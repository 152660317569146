
<script>
  import { Line } from 'vue-chartjs'

export default {
  name:"LineChart",
  props:{
    chartdatas:[]
  },
  extends: Line,
  data(){
      return{        
        chartdata: {        
        labels: this.dataLabel(),
        datasets: this.dataFormat()
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
         callbacks: {
            label: this.fTooltipsCallbacks
          }
        },
        scales: {
          yAxes: [{
            ticks: {
               callback: this.fTickscallback
            }
          }]
        }
      }
    }
  },

  mounted () {
    this.colorsd()
    //console.log(this.chartdata)
    this.renderChart(this.chartdata, this.options)
    
  },
  methods:{
    dynamicColors(){
      var r = Math.floor(Math.random() * 255);
      var g = Math.floor(Math.random() * 255);
      var b = Math.floor(Math.random() * 255);
      var a = 0;
      return "rgb(" + r + "," + g + "," + b + ",1 )";
    },
    colorsd(){ 
      //console.log(this.chartdatas)
     var c=0;
      for (var i in this.chartdatas.data) {
          //this.chartdata.datasets[0].backgroundColor.push(this.dynamicColors());          
      }
      for(var e in this.datasets){
        //this.chartdata.datasets[0].borderColor=this.chartdata.datasets[c].backgroundColor;
        //this.chartdata.datasets[0].borderColor=this.chartdata.datasets[c].backgroundColor;
        c++;
      }
    },
    dataLabel(){
      var labels=""
      if(this.chartdatas.length>0){
        labels=this.chartdatas[0].data_label
      }

        return labels
    },
    dataFormat(){
      var data=[]
      var colord="rgb(255, 99, 132,1)"
       this.chartdatas.forEach((item,ind)=>{
        let hd=false
        if(typeof(item.hide)!="undefined"){hd=item.hide}
        if(ind>0){
            colord=this.dynamicColors()
          }
         data.push({
            label: item.mes,
            borderWidth: 2,
            backgroundColor: ['rgb(0, 0, 0,0)'],
            pointBackgroundColor : colord,
            borderColor: colord,
            data: item.data,
            hidden:hd
          })
       })
      return data
    },
    fTickscallback(value, index, values) {
      return Vue.filter('chartTick')(value,this.chartdatas.format);
    },
     fTooltipsCallbacks(t, d) {
      return Vue.filter('chartTooltips')(t,d,this.chartdatas.format);
    }
  }
}
</script>