<template>
  <div>
      <span class="help-block">&nbsp;</span>
      <table class="table table-hover" v-bind:id="id"  width="100%">
        <thead>
          <tr>
            <th>Plataforma</th>
            <th>Nombre</th>
            <th>Correo electrónico</th>
            <th>Estatus</th>
            <th>Acciones &nbsp;&nbsp;&nbsp;&nbsp;</th>
            <th>Solicitudes</th>
            <th>Responsiva</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="b in data_">
            <td> {{ b.user_portal!=null ? 'TRAMITES.NL - ADMINISTRADOR DE LA TESORERIA' : b.plataforma}}</td>
            <td> {{ b.nombre_completo}} </td>
            <td> {{ b.email}} </td>
            <td> <span v-bind:class="[b.status=='1' ? 'btn-success' : 'label-danger']" class="label  btn-circle">
              {{ b.status=='1' ? 'Activo' : 'Inactivo'}} </span></td>
            <td >
            <a class="btn btn-sm btn-circle" v-bind:class="[b.status=='1' ? 'yellow' : 'green']" data-toggle="modal" href="#portlet-status" title="Activar/Desactivar"  @click="accionStatus(b.id,b.status,b.email,b.plataforma,b)"><i class="fa fa-power-off"></i></a>
            <a class="btn btn-sm btn-circle default" v-if="b.status=='1' " data-toggle="modal" href="#portlet-editar" title="Editar Registro"  @click="accionEditar(b)"><i class="fa fa-cogs"></i></a>
            </td>
            <td class="text-center">
              <a href="#portlet-solicitudes" title="Solicitudes" data-toggle="modal" @click="accionDocumentos('solicitud',b)" ><span aria-hidden="true" class="icon-folder-alt" style="font-size: 22px;" ></span></a>
            </td>
            <td class="text-center">
              <a href="#portlet-solicitudes" title="Responsiva" data-toggle="modal" @click="accionDocumentos('responsiva',b)" ><span aria-hidden="true" class="icon-envelope-letter" style="font-size: 22px;"></span> </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</template>
<script>
  export default {
    name:"TableSampleRecibo",
    props: {id:'',data_:[],lengthMenu:[]},
    mounted () {
      //$('#'+this.id).DataTable();
      TableManaged2.init2();
    },      
    methods:{
      accionStatus(id,status,email,plataforma,data){
        this.$emit('accionStatus', id,status,email,plataforma,data);
      },
      accionEditar(dato){
        this.$emit('accionEditar', dato);
      },
      accionDocumentos(tipo,data){
        this.$emit('accionDocumentos', tipo,data);
      },
    }        
  }
</script>
