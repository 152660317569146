<template>
    <div>
        <slot name="a_link"></slot>
        <div class="dropdown-menu dropdown-content hold-on-click" style="border-color: #000;" role="menu">
            <div class="row">
                <div class="col-md-12">
                    <div class="arrow" style="left: 100%;"></div>
                    <h3 class="popover-title" style="font-weight:bold;"><slot name="title"></slot></h3>
                    <div class="input-group">
                        <slot name="group_input"></slot>
                        <span class="input-group-btn">
                            <slot name="group_button">

                            </slot>                        
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>        
</template>
<script>
  export default {
    name:"DropdownMenu",
    props: [],
  }
</script>