<template>
  <div v-bind:class="clase">
    <div class="portlet light bordered">
      <div class="portlet-title">
        <div class="actions  col-md-1 text-right">
          <slot name="actions"> 

          </slot>
        </div>
        <div class="caption col-md-11">
          <slot name="caption"> </slot>      
        </div>
        <div class="tools ol-md-11">
          <slot name="tools"></slot>
        </div>
        <div class="caption  col-md-12">
          <slot name="caption2"> </slot>      
        </div>        
      </div>
      <div class="portlet-body">
        <div data-rail-visible="1" data-rail-color="yellow" data-handle-color="#a1b2bd">          
          <slot name="body"> 

          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name:"Portlet",
    props: ['clase'],
  }
</script>
