<template>
    <div>
    <modal :id="'modal-detalle'" :modal="'modal-lg'">      
        <template slot="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title"><i class="fa fa-navicon"></i> Inscripción al Registro Estatal de Contribuyentes</h4>
        </template>
        <template slot="modal-body">
        <div class="row blockui_sample_3_2_element"> 
            <portlet :clase="'container col-md-12'" >
                <template slot="caption"><span class="caption-subject bold uppercase">Datos generales</span></template>
                <template slot="body">         
                    <div class="row"> 
                        <div class="col-md-4">
                            <label class="label-det">Folio de seguimiento:</label><span class="help-block">{{ typeof(data_[0].preacks.short_id)!=='undefined' ? data_[0].preacks.short_id : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Tipo de tramite:</label><span class="help-block">{{ typeof(data_[0].preacks.flow_alias)!=='undefined' ?catalogoTramite[data_[0].preacks.flow_alias]  : '' }}</span>
                        </div>    
                        <div class="col-md-4">
                            <label class="label-det">RFC:</label><span class="help-block">{{ typeof(data_[0].rfc)!=='undefined' ? data_[0].rfc : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Tipo de contribuyente:</label><span class="help-block">{{ typeof(data_[0].taxpayer_type)!=='undefined' ? data_[0].taxpayer_type =='natural_person' ? 'PERSONA FISICA':'PERSONA MORAL' :''}}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Razón social:</label><span class="help-block">{{ typeof(data_[0].data.business_name)=='undefined' ?  data_[0].data.name+' '+ data_[0].data.lastname +' '+  data_[0].data.second_lastname : typeof(data_[0].data.business_name)!=='undefined' ? data_[0].data.business_name : '' }}</span>
                        </div>
                        <div class="col-md-4" v-if="data_[0].data.constitution_date">
                            <label class="label-det">Fecha de constitución:</label>
                            <span class="help-block">{{ typeof(data_[0].data.constitution_date)!=='undefined' ? data_[0].data.constitution_date.substring(0, 10) : '' }}</span>
                        </div>
                        <div class="col-md-4" v-if="data_[0].data.birthdate">
                            <label class="label-det">Fecha de nacimiento:</label>
                            <span class="help-block">{{ typeof(data_[0].data.birthdate)!=='undefined' ? data_[0].data.birthdate.substring(0, 10) : '' }}</span>
                        </div>
                        <div class="col-md-4" v-if="data_[0].data.regimen">
                            <label class="label-det">Régimen:</label>
                            <span class="help-block">{{ typeof(data_[0].data.regimen)!=='undefined' ? data_[0].data.regimen : '' }}</span>
                        </div>
                        <div class="col-md-4" v-if="data_[0].data.curp">
                            <label class="label-det">CURP:</label>
                            <span class="help-block">{{ typeof(data_[0].data.curp)!=='undefined' ? data_[0].data.curp : '' }}</span>
                        </div>
                        <div class="col-md-4" v-if="data_[0].data.gender">
                            <label class="label-det">Genero:</label>
                            <span class="help-block">{{ typeof(data_[0].data.gender)!=='undefined' ? data_[0].data.gender : '' }}</span>
                        </div>
                        <div class="col-md-4" v-if="data_[0].data.nationality">
                            <label class="label-det">Nacionalidad:</label>
                            <span class="help-block">{{ typeof(data_[0].data.nationality)!=='undefined' ? data_[0].data.nationality : '' }}</span>
                        </div>
                    </div>
                </template>
            </portlet>
            <portlet :clase="'container col-md-12'" v-if="data_[0].constitutive_acts[0]">
                <template slot="caption">  <span class="caption-subject bold uppercase">Datos de documento protocolizado</span></template>
                <template slot="body">         
                    <div class="row">           
                        <div class="col-md-4">
                            <label class="label-det">Libro:</label><span class="help-block">{{ typeof(data_[0].constitutive_acts[0])!=='undefined' ? data_[0].constitutive_acts[0].book : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Foja:</label><span class="help-block">{{ typeof(data_[0].constitutive_acts[0])!=='undefined' ? data_[0].constitutive_acts[0].sheet : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Fecha de firma de constitución:</label><span class="help-block">{{ typeof(data_[0].constitutive_acts[0])!=='undefined' ? data_[0].constitutive_acts[0].date_signature_constitution : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Datos del RPP y del comercio:</label><span class="help-block">{{ typeof(data_[0].constitutive_acts[0])!=='undefined' ? data_[0].constitutive_acts[0].rpp_and_trade_data : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Nacionalidad de constitución:</label><span class="help-block">{{ typeof(data_[0].constitutive_acts[0])!=='undefined' ? data_[0].constitutive_acts[0].nationality : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">RFC de la notaria publica:</label><span class="help-block">{{ typeof(data_[0].constitutive_acts[0])!=='undefined' ? data_[0].constitutive_acts[0].rfc_notary_public : '' }}</span>
                        </div>
                    </div>
                </template>
            </portlet>
            <portlet :clase="'container col-md-12'" v-if="data_[0].legal_representatives[0]" >
                <template slot="caption">  <span class="caption-subject bold uppercase">Datos del representante legal</span></template>
                <template slot="body">         
                    <div class="row">           
                        <div class="col-md-4">
                            <label class="label-det">CURP:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].curp : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">RFC:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].rfc : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Entidad:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].entity : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Nombre(s):</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].name : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Apellido paterno:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].lastname : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Apellido materno:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].second_lastname : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Fecha de nacimiento:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].birthdate : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Nacionalidad:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].nationality : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Sexo:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].gender=='female' ? 'Femenino': 'Masculino' : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Correo electrónico:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].email : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Teléfono:</label><span class="help-block">{{ typeof(data_[0].legal_representatives[0])!=='undefined' ? data_[0].legal_representatives[0].phone : '' }}</span>
                        </div>
                    </div>
                </template>
            </portlet>
            <portlet :clase="'container col-md-12'" v-if="data_[0].tax_residences[0]">
                <template slot="caption">  <span class="caption-subject bold uppercase">Datos del domicilio fiscal</span></template>
                <template slot="body">         
                    <div class="row">           
                        <div class="col-md-4">
                            <label class="label-det">Código postal:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].zip : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Entidad:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].state : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Colonia:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].neighborhood : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Tipo de vialidad:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].type_of_road : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Nombre de la vialidad:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].street : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">No. exterior:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].exterior : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">No. interior:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].interior : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Entre calle:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0].between[0])!=='undefined' ? data_[0].tax_residences[0].between[0].street : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Y calle:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0].between[1].street)!=='undefined' ? data_[0].tax_residences[0].between[1].street : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Tipo inmueble:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].property_type : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Registro del medidor de agua y drenaje:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].water_and_drain_meter : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Referencias adicionales:</label><span class="help-block">{{ typeof(data_[0].tax_residences[0])!=='undefined' ? data_[0].tax_residences[0].references : '' }}</span>
                        </div>
                    </div>
                </template>
            </portlet>
            <portlet :clase="'container col-md-12'" v-if="data_[0].mean_contacts[0]">
                <template slot="caption">  <span class="caption-subject bold uppercase">Datos de medios de contacto</span></template>
                <template slot="body">         
                    <div class="row">           
                        <div class="col-md-4" v-for="contact in data_[0].mean_contacts[0].data">
                            <label class="label-det">{{ contact.contact_type=='email' ? 'Correo electrónico':'Teléfono fijo' }}:</label><span class="help-block">{{ contact.value }}</span>
                        </div>
                    </div>
                </template>
            </portlet>
            <portlet :clase="'container col-md-12'" v-if="data_[0].inscription_forms[0]">
                <template slot="caption">  <span class="caption-subject bold uppercase">Datos de la solicitud de inscripción</span></template>
                <template slot="body">         
                    <div class="row">
                        <div class="col-md-4">
                            <label class="label-det">Actividad preponderante:</label><span class="help-block">{{ typeof(data_[0].inscription_forms[0])!=='undefined' ? data_[0].inscription_forms[0].preponderant_activity : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Fecha de inicio de operaciones:</label><span class="help-block">{{ typeof(data_[0].inscription_forms[0])!=='undefined' ? data_[0].inscription_forms[0].operations_started_date : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Fecha de alta ante el IMSS:</label><span class="help-block">{{ typeof(data_[0].inscription_forms[0])!=='undefined' ? data_[0].inscription_forms[0].imss_started_date : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Número de registro patronal:</label><span class="help-block">{{ typeof(data_[0].inscription_forms[0])!=='undefined' ? data_[0].inscription_forms[0].patronal_register_number : '' }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="label-det">Número de trabajadores:</label><span class="help-block">{{ typeof(data_[0].inscription_forms[0])!=='undefined' ? data_[0].inscription_forms[0].employees_number : '' }}</span>
                        </div>
                        <div class="col-md-12" v-if="data_[0].inscription_forms[0]">
                            <label class="label-det">Obligaciones fiscales:</label>
                        </div>
                        <div class="col-md-4" v-for="obligacion in data_[0].inscription_forms[0].taxes_data">
                            <label class="label-det">{{ obligacion.name }}</label><span class="help-block">{{ obligacion.value }}</span>
                        </div>

                    </div>
                </template>
            </portlet>
            <portlet :clase="'container col-md-12'" v-if="data_[0].rec_files[0]">
                <template slot="caption">  <span class="caption-subject bold uppercase">Documentos Adjuntos</span></template>
                <template slot="body">         
                    <div class="row">           
                        <tableSample :lengthMenu="lengthMenu" :data_="data_files" :id="'sample_3'" v-if="renderTableFiles"/>
                    </div>
                </template>
            </portlet>
        </div>
        </template>
        <template slot="modal-footer">
            <button type="button" data-dismiss="modal" class="btn default">Cerrar</button>
        </template>
    </modal>
    </div>
</template>
<script>
import axios from 'axios'
import modal from '../General/Modal.vue'
import portlet from '../General/Portlet.vue'
import tableSample from '../Individual/RecFilesTable.vue'

export default {
    name: 'RecModal',
    components: {axios,modal,portlet,tableSample},
    props: { id:'' },
    data(){return{
            data_:[{
                rfc:'',
                data:{
                    business_name:''
                },
                constitutive_acts:[],
                legal_representatives:[],
                tax_residences:[],
                mean_contacts:[],
                inscription_forms:[],
                rec_files:[],
                preacks:[]
            }],
            renderTableFiles:false,
            lengthMenu:[],
            data_files:[],
            catalogoTramite : {
                rec: 'Crear registro estatal de contribuyentes.',
                adof: 'Aviso disminución de obligaciones fiscales',
                aaof: 'Aviso aumento de obligaciones fiscales',
                acd: 'Aviso cambio de domicilio',
                aael: 'Aviso de apertura de establecimientos o locales',
                acel: 'Cierre de establecimientos o locales',
                acdrs: 'Aviso cambio de denominación o razón social',
                acrl: 'Aviso cambio de representante legal',
                asa: 'Aviso suspención de actividades',
                aract: 'Aviso reanudación de actividades',
                ail: 'Aviso inicio de liquidación',
                aasu: 'Aviso apertura de sucesión',
                abrec: 'Aviso baja del registro estatal de contribuyentes'
            }
        }
    },
    mounted () {
      
    },      
    methods:{
        findDetalle(){ 
            this.loagingC(true);
            this.renderTableFiles=false;
            axios.post(`${process.env.APP_URL}/rec-find`,{
                id: this.id
            })
            .then((response)=>{
                this.data_=response.data;
                this.data_files=response.data[0].rec_files;
                this.loagingC(false);
                this.renderTableFiles=true;
            }).catch(error=>{
                this.notifications('400',"Registros no encontrados");
                console.log(error);
                this.renderTableFiles=false;
                this.loagingC(false);
            })
        },
        loagingC(value, index, values) {
            return Vue.filter('loadingCharge')(value);
        },
    },
    watch:{
        id(id){
            this.findDetalle();
        }
    }
}
</script>
<style>
.label-det{
    font-weight:bold
}
</style>