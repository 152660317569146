<template>
  <div>
      <table class="table table-hover" v-bind:id="'sample_2'"  width="100%"> </table>
  </div>
</template>
<script>
  export default {
    name:"RecTable",
    props: {id:'',data_:[],lengthMenu:[]},
    mounted () {
      this.createTable();
      window.RecTableComponent = this;
    },      
    methods:{
      accionDetalle(dato){
        //console.log(dato);
        this.$emit('guardar', dato);
      },
      createTable(){
        $('#sample_2').DataTable( {
          "data": this.data_,
            "columns": [
              {"data": "identifications.rfc",title:"RFC"},
              {"data": "identifications.taxpayer_type",title:"Tipo de contribuyente",render:this.tipo},
              {"data": "_id",title:"Razón social",render:this.nombre},
              {"data":"identifications.rfc",title:"Fecha de constitución",render:this.fecha},
              {"data":"identifications.data.regimen",title:"Régimen",render: this.regimen},
              {"data":"_id","render": this.btnEditar},              
            ]
        });
      },
      btnEditar( data, type, row, meta){
        return "<a class='btn btn-icon-only btn-circle green' data-toggle='modal' href='#modal-detalle' title='Detalles'  onclick='accionDetalle("+JSON.stringify(row._id)+")'><i class='fa fa-navicon'></i></a>";
      },
      tipo( data, type, row, meta){
          let tipo="PERSONA FISICA";
          if(row.identifications.taxpayer_type=="legal_entity"){tipo="PERSONA MORAL";}
        return tipo;
      },
      nombre( data, type, row, meta){
          let nombre=row.identifications.data.business_name;
          if(typeof(nombre)=="undefined"){ nombre= row.identifications.data.name +" "+ row.identifications.data.lastname + " " + row.identifications.data.second_lastname;}
        return nombre;
      },
      fecha( data, type, row, meta){
          let f=row.identifications.data.constitution_date;
          if(typeof(f)=="undefined"){ f= row.identifications.data.birthdate;}
        return f;
      },
      regimen( data, type, row, meta){
          let r=row.identifications.data.regimen;
          if(typeof(r)=="undefined"){ r= "";}
        return r;
      },

    }   
  }
</script>