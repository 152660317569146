<template>
  <div>
  <portletsample :clase="'container col-md-12'">
    <template slot="actions">
      <a class="btn btn-circle btn-icon-only btn-default"   href="#portlet" data-toggle='modal' title='Agregar'>
        <i class="fa fa-plus"></i></a>
      <a class="btn btn-circle btn-icon-only btn-default fullscreen" href="javascript:;"> </a>          
    </template>
    <template slot="caption">
      <div class="row"> 
        <div class='col-md-6'>          
          <span class="caption-subject bold uppercase"> <i class="fa fa-bar-chart-o"></i> Bancos {{title_c}}</span>          
        </div> 
      </div>  
    </template>
    <template slot="caption2">
     <div class="row">
        <div class='col-md-4'> 
          <div class='form-group'>   
           <v-date-picker v-model="range" is-range >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center input-group" >
                <span class='input-group-addon'>De</span>
                <input :value="inputValue.start" v-on="inputEvents.start" class="form-control  rounded border rounded"/>
                <span class='input-group-addon'>A</span>
                <input :value="inputValue.end" v-on="inputEvents.end" class="form-control  rounded border rounded green" />
              </div>
            </template>
          </v-date-picker>
          </div>              
        </div>
        <div class='col-md-2'>
          <div class='form-group'>
            <select2 class="form-control" :options="options" v-model="id_banco"  v-on:eventchange="findTramites()">
              <option value="Todos">Todos</option>
              <option v-for="b in bancos" :value="b.id">{{b.nombre}}</option>
            </select2>
          </div>
        </div>
        <div class='col-md-2'>
          <div class='form-group'>
            <select2 class="form-control" :options="options" v-model="title_c" @eventchange="changeTipo">
              <option value="Transacciones">Transacciones</option>
               <option value="Monto Total">Monto Total</option>
            </select2>
          </div>
        </div>            
        <div class="col-md-1"> <button class="btn green" v-on:click="findTramites()"> Buscar</button> </div>
        <!--<div class="col-md-2"> <export-excel class   = "btn btn-default" :data   = "data_p" worksheet = "My Worksheet" :name="name_file"> Descargar Excel </export-excel> </div>-->
        <div class="col-md-3"> <button class   = "btn btn-default" :disabled="isDisabledp" title="Excel Transacciones por fecha de conciliacion" v-on:click="ReporteIngresosTransacciones()">Descargar Excel Ingresos <span v-bind:class="[{ spinner: isDisabledp}]">&nbsp;&nbsp;&nbsp;&nbsp;</span></button> </div>
      </div>
    </template>
    <template slot="body">         
        <line-chart v-if="loaded" :chartdatas="chartdatas"/>
    </template>
    <!-------------------  MODAL -------------->
  </portletsample>
    <modalconfirmation :id="'portlet'" >      
      <template slot="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
          <h4 class="modal-title">Agregar Consulta</h4>
      </template>
      <template slot="modal-body">
        <v-date-picker v-model="rangeAdd" is-range >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center input-group" >
              <span class='input-group-addon'>De</span>
              <input :value="inputValue.start" v-on="inputEvents.start" class="form-control  rounded border rounded"/>
              <span class='input-group-addon'>A</span>
              <input :value="inputValue.end" v-on="inputEvents.end" class="form-control  rounded border rounded green" />
            </div>
          </template>
        </v-date-picker>
        <span class="help-block"></span>
      </template>
      <template slot="modal-footer">
        <button type="button" data-dismiss="modal" class="btn default">Cancelar</button>
        <button type="button" data-dismiss="modal" class="btn green" v-on:click="findTramitesAdd()">Agregar</button>
      </template>
    </modalconfirmation>
</div>
</template>

<script>

import axios from 'axios'
import VCalendar from 'v-calendar'
import excel from 'vue-excel-export'

import LineChart from './Chartsjs/LineChart.vue'
import select2 from './General/Select.vue'
import modalconfirmation from './General/ModalConfirmation.vue'
import portletsample from './General/Portlet.vue'

export default {
  name: 'ChartHorizontal',
  components: { LineChart, VCalendar,select2,modalconfirmation,portletsample,excel},
  data(){return{
    id_banco:"Todos",
    bancos:[],
    data_c:[],
    data_p:[],
    title_c:"Transacciones",
     dot: 'red',
    range: {
      start:this.firtsDate(),
      end: new Date(),       
    },
    rangeAdd: {
      start:this.firtsDate(),
      end: new Date(),       
    },
    btn_p:false,     
    message: null,
    loaded: true,
    chartdatasAll: [],
    chartdatas: [],
     options: [
      
    ],
    name_file:this.filename()
  }
  },
  methods:{
    obtenerBancos(){
      axios.get(`${process.env.APP_URL}/banco-find-all`)
      .then((response)=>{              
        this.bancos=response.data 
      })
    },
    findTramites(){
      this.loaded = false
      axios.post(`${process.env.APP_URL}/reporte-bancos-c`, {
        incio: new Date(this.range.start).toISOString().slice(0,10),
        fin: new Date(this.range.end).toISOString().slice(0,10),
        status:"p",
        banco_id:this.id_banco
      })
      .then((response)=>{
        this.loaded = true
        this.data_c=response.data.data_chart
        this.data_p=response.data.data_report
        this.chartdatas=this.findarray()
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    findTramitesAdd(){
      this.loaded = false
      axios.post(`${process.env.APP_URL}/reporte-bancos-c`, {
        incio: new Date(this.rangeAdd.start).toISOString().slice(0,10),
        fin: new Date(this.rangeAdd.end).toISOString().slice(0,10),
        status:"p",
        banco_id:this.id_banco
      })
      .then((response)=>{
        this.loaded = true
        response.data.data_chart.forEach((item,ind)=>{
          this.data_c.push(item)
        })
        response.data.data_report.forEach((item,ind)=>{
          this.data_p.push(item)
        })        
        this.chartdatas=this.findarray()
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    ReporteIngresosTransacciones(){ 
      this.btn_p=true     
      axios.post(`${process.env.APP_URL}/reporte-ingresos-b`,{
        incio: new Date(this.range.start).toISOString().slice(0,10),
        fin: new Date(this.range.end).toISOString().slice(0,10)},{ responseType: 'blob'})
      .then((response)=>{          
         const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download','Reporte-Ingresos-Fecha-Inicio-'+ this.name_file);
                document.body.appendChild(link);
                link.click();
        this.btn_p=false
      }).catch(error=>{
       console.log(error)
       this.btn_p=false
      })
    },
    changeTipo(event) {
      this.loaded = false
      this.chartdatas=this.findarray()
      let selft=this
      setTimeout(function() {selft.loaded=true}, 100)
      //this.loaded = true      
    },
    findarray(){

     var data= this.data_c
     var d=[]
     
      if(this.title_c=="Transacciones")
      { 
        data.forEach((item,ind)=>{
          Object.assign(data[ind],{"data": item.data_transacciones})
        })
         d=Object.assign(data,{format:"0"})
        
      }else{
        data.forEach((item,ind)=>{
          Object.assign(data[ind],{"data": item.data_total})
        })
        d=Object.assign(data,{format:"1"})
      }
      
      return d
    },
    firtsDate(){
      var date = new Date();
      var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
      return primerDia
    },
    filename(){
      var date =  new Date()
     return "reporte_bancos-" + date.getFullYear()+""+(date.getMonth() + 1) +""+date.getDate()+"-"+date.getHours()+""+date.getMinutes()+""+date.getSeconds()+".xls"
    }

  },
  computed: {
    isDisabledp: function(){
      return this.btn_p;
    },
  },
  mounted() {
    //$('#title_c').select2({});
    try {
      this.findTramites() 
      this.obtenerBancos()
    } catch (e) {
      console.error(e)
    }
  }
}
</script>