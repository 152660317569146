<template>
    <div id="" class=" w-100 m-3" >
        <div class="row" style="width: 100%;">
            <div class="portlet light bordered">
                <div class="portlet-title">
                    <div class="caption">
                        <span class="caption"> Avisos WS </span>
                    </div>
                    <div class="actions" > 
                        <a href="#portlet-editar"  data-toggle="modal" class="btn btn-circle btn-icon-only default" data-original-title="" title="añadir aviso" v-on:click="editarAux(false, null,0)" ><i class="fa fa-wrench"></i>
                            </a>
                    </div>
                </div>
                <div class="portlet-body">
                    <div class="form-body">
                        <div class="row">
                            <div class="" style="display: flex;justify-content: flex-end;margin-right: 2%;">
                                <div class="form-group" style="display: flex;align-items: center;gap: 10px;">
                                    <span class="help-block"></span>
                                    Servicio de catastro esta {{ estaActivo ? 'Activo': 'Inactivo' }}
                                    <div :class="['toggle', { 'toggle--on': estaActivo  }]" @click="toggle(-1)" :title="estaActivo ? 'Activo': 'Inactivo'">
                                        <div class="toggle__circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-ms-12">
                                <div class="modal-body">
                                    <div class="table-responsive">
                                        <table class="table grid" cellspacing="1">
                                            <thead>
                                                <tr>
                                                    <th>Encabezado</th>
                                                    <th>Mensaje</th>
                                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                    <th>Botón de control</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(aviso, index) in avisos " :key="index">
                                                    <td>{{ aviso.encabezado  }}</td> 
                                                    <td class="d-flex" style="justify-content: space-between;"> 
                                                        <div>{{ aviso.mensaje }}</div>
                                                    </td>
                                                    <td>
                                                        <button class='btn btn-icon-only btn-circle blue' v-on:click="editarAux(true, index,aviso.id)"  data-toggle="modal" href="#portlet-editar" title='Editar aviso'><i class='fa fa-pencil'></i></button>
                                                        <button class='btn btn-icon-only btn-circle red' v-on:click="deleteAviso(index, aviso.id,aviso.encabezado, aviso.mensaje)"  title='Eliminar aviso' data-toggle="modal" href="#modal-confirmar" ><i class='fa fa-trash'></i></button>
                                                    </td>
                                                    <td> 
                                                        <div :class="['toggle', { 'toggle--on': aviso.estatus == 'true'  }]" @click="toggle(index)" :title="aviso.estatus == 'true' ? 'Activo' : 'Inactivo'">
                                                            <div class="toggle__circle"></div>
                                                        </div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>                              
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal id="portlet-editar" :modal="`modal-lg`">
            <template slot="modal-header">
                Configuracion avisos WS
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            </template>
            <template slot="modal-body">
                <div class="d-flex" style="flex-direction: column;margin-bottom: 20px;"> 
                    <span>Encabezado</span>
                    <input v-if="!editar" class="form-control" type="text" v-model="auxEncabezado" maxlength="200" /> 
                    <input v-else class="form-control" type="text" v-model="auxEditarEncabezado" maxlength="200" /> 
                </div>
                
                <div class="d-flex" style="flex-direction: column;"> 
                    <span>Mensaje</span>
                    <textarea v-if="!editar" class="form-control text_area" type="text" v-model="auxMensaje" rows="10"/>
                    <textarea v-else class="form-control text_area" type="text" v-model="auxEditarMensaje"rows="10" />
                </div>


            </template>
            <template slot="modal-footer">

                    <button class="btn blue" style="margin-top: 10px;" @click="saveAviso">Guardar</button>
                    <button data-dismiss="modal" id="closeModal" class="btn red" style="margin-top: 10px;">Cancelar</button>

            </template>
        </Modal>

        <div class="row" style="width: 100%;">
            <div class="portlet light bordered">
                <div class="portlet-title">
                    <div class="caption">
                        <span class="caption"> Bitacora </span>
                    </div>
                    <div class="actions" >
                        <a class="btn btn-circle btn-icon-only btn-success" target="_blank" :href="link_download" data-toggle='modal' title='Descargar Excel' ><i class="fa fa-file-excel-o"></i></a>
                    </div>
                </div>
                <div class="portlet-body">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-2 col-ms-12">
                                <div class="form-group">
                                    <span class="help-block"></span>
                                    <!-- <label >Tramite (Selecciona)</label> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-12 col-ms-12">
                                <div class="modal-body">
                                    <div class="table-responsive">
                                        <table class="table grid" cellspacing="1">
                                            <thead>
                                                <tr>
                                                    <th>Responsable</th>
                                                    <th>Encabezado</th>
                                                    <th>Mensaje</th>
                                                    <th>Estatus</th>
                                                    <th>Fecha de inicio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in bitacora">
                                                    <td>{{ item.responsable }}</td>
                                                    <td>{{ item.encabezado }}</td> 
                                                    <td>{{ item.mensaje }}</td> 
                                                    <td>{{ item.status == 1 ? 'Activo' : 'Inactivo' }}</td> 
                                                    <td> {{ item.fecha_i }}</td> 
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>                              
                        </div> -->
                        <div class="row 2">
                            <div class="col-md-12 col-ms-12">
                                <div class="modal-body">
                                    <div class="table-responsive">
                                        <table class="table grid" id="bitacora" cellspacing="1">
                                            <thead>
                                                <tr>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>                              
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalconfirmation :id="'modal-confirmar'" >      
            <template slot="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Confirmar</h4>
            </template>
            <template slot="modal-body">
                ¿Estas seguro de eliminar el aviso: <label style="color: #cb5a5e;">{{lbl_encabezado}}</label>?
            </template>
            <template slot="modal-footer">
                <button type="button" data-dismiss="modal" class="btn default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn green" v-on:click="deleteConfirm()"><i class="fa fa-check"></i> Confirmar</button>
            </template>
        </modalconfirmation>
    </div>

</template>
<script>
import Axios from 'axios';
import Modal from './Modal.vue'
import modalconfirmation from './ModalConfirmation.vue'
export default {
    name:"CatastroComponent",
    props: ['user'],
    components:{
        Modal,modalconfirmation
    },
    data(){ 
        return {
            id_registro:'',
            estaActivo: undefined,
            encabezado: '',
            mensaje: '',
            auxEncabezado: '',
            auxEditarEncabezado: '',
            auxMensaje: '',
            auxEditarMensaje: '',
            bitacora: [],
            avisos: [],
            editar: false,
            index: false,
            link_download: `${process.env.APP_URL}/download-catastro`,
            lbl_encabezado:'',
            lbl_mensaje:''
        }
    },
    mounted(){
        // this.getStatusCatastro()
        this.getAvisosCatastro()
        this.getBitacora()
    },
    methods:{

        deleteAviso(index, id,encabezado,mensaje){
            this.id_registro=id;
            this.lbl_encabezado=encabezado;
            this.lbl_mensaje=mensaje;
            this.index=index;
        },
        
        async deleteConfirm(){
            let index=this.index;
            let id= this.id_registro;
            let _encabezado= this.lbl_encabezado;
            let _mensaje= this.lbl_mensaje;
            
            if(this.avisos[index].estatus == 'true'){
                return
            }
            
            await Axios.post(`${process.env.APP_URL}/delete-catastro`,{
                id: id,
                responsable : this.user.name,
                encabezado : _encabezado,
                mensaje : _mensaje,
            })
            await this.getAvisosCatastro()
            this.$forceUpdate()
            this.index=false;
            this.id_registro='';
            this.lbl_encabezado='';
            this.lbl_mensaje='';
        },
        async getBitacora(){
            const response = await Axios(`${process.env.APP_URL}/get-bitacora-catastro`)
            this.bitacora = response?.data
            $('#bitacora').DataTable( {
                "data": this.bitacora,
                "destroy": true,
                "columns": [
                    { data: "id", title: "ID"},
                    { data: "responsable", title: "Responsable de activación"},
                    { data: "encabezado", title: "Encabezado"},
                    { data: "mensaje", title: "Mensaje", width: '50%'},
                    { data: "fecha_i", title: "Fecha de inicio"},
                    { data: "fecha_t", title: "Fecha de termino"},
                ],
                order: [[0,'desc']]
            });
        },
        responsable( data, type, row, meta){
            return row.historial_inicio==null ? "": row.historial_inicio.responsable;
        },
        historial_inicio( data, type, row, meta){
            return row.historial_inicio==null ? "": row.historial_inicio.created_at;
        },
        historial_baja( data, type, row, meta){
            return row.historial_baja==null ? "": row.historial_baja.created_at;
        },
        editarAux(editar , index = null,id){
            this.editar = editar 
            this.index = index
            this.id_registro=0;
            if(editar && index != null){
                this.id_registro=id;
                this.auxEditarEncabezado = this.avisos[index].encabezado
                this.auxEditarMensaje = this.avisos[index].mensaje
                this.$forceUpdate()
            }

        },
        async saveAviso(){
            if(this.editar){
                await Axios.post(`${process.env.APP_URL}/update-text-catastro`,{
                    id: this.id_registro,
                    encabezado: this.auxEditarEncabezado,
                    mensaje: this.auxEditarMensaje,
                    responsable : this.user.name,
                })
                
            }else{
                await Axios.post(`${process.env.APP_URL}/upload-avisos-catastro`,{
                    encabezado: this.auxEncabezado,
                    mensaje: this.auxMensaje,
                    responsable : this.user.name,
                })
            }
            this.mensaje = this.auxMensaje
            this.encabezado = this.auxEncabezado
            await this.getAvisosCatastro()
            this.$forceUpdate()
            document.getElementById('closeModal').click()
            this.auxEncabezado='';
            this.auxMensaje='';
            this.getBitacora();
        },
        async setBitacoraCatastro(index){
            const activo = this.avisos.find(aviso => aviso.estatus == 'true')
            let status
            let _id=0
            let mensaje
            let encabezado
            console.log(index, activo);
            if(index >= 0 && activo){
                let auxId = this.avisos[index].estatus
                 _id = this.avisos[index].id
                let fecha_i
                let fecha_t
                if(auxId == 'true'){
                    fecha_i = new Date()
                }else{
                    fecha_i = this.avisos[index]?.updated_at ?? new Date()
                    fecha_t = new Date()
                }
                status  = this.avisos[index].estatus
            }else{
                mensaje = 'Servicio de catastro esta Activo'
                encabezado = 'Servicio Catastro'
                status = 'true'
            }

            await Axios.post(`${process.env.APP_URL}/set-bitacora-catastro`,{
                'responsable' : this.user.name,
                'id' : _id,
                'encabezado' : encabezado ?? this.avisos[index].encabezado,
                'mensaje' : mensaje ?? this.avisos[index].mensaje,
                'status' : status == 'true' ? 1 : 0,
                'fecha_i': new Date(),
            })

        },
        async updateAvisoCatastro(id, estatus){
            const response = await Axios.post(`${process.env.APP_URL}/update-aviso-catastro`,{
                'id' : id,
                'estatus' : estatus,
                'responsable' : this.user.name,
            })

        },
        async updateAllAvisosCatastro(){
             await Axios.post(`${process.env.APP_URL}/update-all-avisos-catastro`)

        },
        async getAvisosCatastro(){
            const response = await Axios(`${process.env.APP_URL}/get-avisos-catastro`)
            // console.log(response?.data);
            this.avisos = response.data
            // this.radio = 1
            this.$forceUpdate()
        },
        async getStatusCatastro(){
            const response = await Axios(`${process.env.APP_URL}/get-status-catastro`)
            // console.log(response?.data?.status);
            this.estaActivo = response?.data?.status
            // this.radio = 1
            this.$forceUpdate()
        },
        async toggle(index){
            Vue.filter('loadingCharge')(true);
            //await this.updateAllAvisosCatastro()
            
            if(index == -1){
                Vue.filter('loadingCharge')(false);
                //await this.getAvisosCatastro()
                //await this.setBitacoraCatastro()
                //await this.getBitacora()
               // Vue.filter('loadingCharge')(false);
                return
            }

            await this.updateAvisoCatastro(this.avisos[index].id, this.avisos[index].estatus == "false" ? 'true' : 'false'  )

            
            await this.getAvisosCatastro()
            await this.setBitacoraCatastro(index)
            await this.getBitacora()
            this.$forceUpdate()
            // this.estaActivo = !this.estaActivo
            // this.setStatusCatastro(this.estaActivo? 1: 0)
            // this.bitacora.push({responsable: this.user.name , encabezado: this.encabezado , fecha_i: 'todo', fecha_t: 'todo' })
            Vue.filter('loadingCharge')(false);
        }   
        
    },
    watch:{
        avisos: {
            handler: function(newValue){
                let activo = newValue.find(aviso => aviso.estatus == 'true')
                if(activo){
                    this.estaActivo = false
                }else{
                    this.estaActivo = true
                }
            },
            deep: true
        }
    }
  }
</script>

<style scoped>
#switch {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  flex-direction: column;
  /* background-color: #f0f0f0; */
}

.toggle {
  margin-top: 1px;
  width: 5vw;
  height: 2vw;
  background-color: #ccc;
  border-radius: 6vw !important;
  position: relative;
  cursor: pointer;
  transition: background-color 1s cubic-bezier(0.25, 1.5, 0.5, 1);
}

.toggle--on {
  background-color: #4caf50;
}

.toggle__circle {
    width: 1.4vw;
    height: 1.4vw;
    background-color: white;
    border-radius: 50% !important;
    position: absolute;
    top: 0.3vw;
    left: 0.9vw;
    transition: transform 1s cubic-bezier(0.25, 1.5, 0.5, 1);
}

.toggle--on .toggle__circle {
  transform: translateX(2vw);
}

.estatus{
    margin-left: 6vw;
    margin-top: 0.3vw;
    font-size: x-large;
}

.d-flex{
    display: flex;
}
.text_area {
  resize: none;
}
</style>
