<template>
    <div>
        <table class="table table-hover" v-bind:id="'sample_3'"  width="100%"> </table>
    </div>
  </template>
  <script>
    export default {
      name:"RecTable",
      props: {id:'',data_:[],lengthMenu:[]},
      mounted () {
        this.createTable();
      },      
      methods:{
        createTable(){
          $('#sample_3').DataTable( {
            "data": this.data_,
            "columns": [
              {"data": "_id",title:"Tipo Documento",render:this.lblTipo},
              {"data": "file_name",title:"Nombre de Archivo"},
              {"data":"_id","render": this.btnDescarga},              
            ],
            "pageLength": 5,
            "lengthMenu": [
                [5, 15, 20, -1],
                [5, 15, 20, "All"] // change per page values here
            ],
          });
        },
        btnDescarga( data, type, row, meta){
          return "<a class='btn btn-icon-only btn-circle green' data-toggle='modal' href='https://slsrecattachments-prod.s3.amazonaws.com/"+row.key+"' target='_blank' title='Descarga'><i class='fa fa- fa-cloud-download'></i></a>";
        },
        lblTipo( data, type, row, meta){
            let tipo=row.file_key;
            if(row.file_key=='cif_sat'){
                tipo="Constancia de Situación Fiscal ";
            }
            if(row.file_key=='address_proof'){
                tipo="Comprobante de Domicilio ";
            }
            if(row.file_key=='legal_representative_identification'){
                tipo="Identificación Oficial del Representante Legal";
            }
            if(row.file_key=='legal_representative_power'){
                tipo="Carta Poder Notarial del Representante Legal";
            }
            if(row.file_key=='identification_id'){
                tipo="Identificación Oficial";
            }
            if(row.file_key=='constitutive_act'){
                tipo="Acta Constitutiva";
            } 
            return tipo;
        }
      }   
    }
  </script>