<template>
    <div>
      <table class="table table-hover" v-bind:id="id"  width="100%">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Usuario</th>
            <th>Recibo Publicado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="b in data_">
            <td>{{ formatDate(b.fecha) }}</td>
            <td>{{ b.hora }}</td>
            <td width="40%">{{ b.usuario }}</td>
            <td width="20%">
             <center> <a class="btn btn-icon-only btn-circle green" data-toggle="modal" href="#modal-historial" title="Visualizar recibo"  @click="acciontabla(b)"><i class="fa fa-eye"></i></a></center>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  <script>
    export default {
      name:"TableSampleRecibo",
      props: {id:'',data_:[],lengthMenu:[]},
      mounted () {
        $('#'+this.id).DataTable({order: [[1,'desc'],[0,'desc']]});
        //TableManaged2.init2();
      },      
      methods:{
        acciontabla(dato){
          this.$emit('reciboPublicado', dato);
        },
        formatDate(date_){
          let date_format="";
          date_=date_.split("-");
          date_format= date_[2] + "/" + date_[1] + "/"+date_[0] ;
          return date_format;
        }
      }        
    }
  </script>