<template>
    <div>
        <table class="table table-hover" v-bind:id="id"  width="100%">
          <thead>
            <tr>
              <th>Folio ID</th>
              <th>Licencia</th>
              <th>Nombre Comercial</th>
              <th>RFC</th>
              <th>Municipio</th>
              <th>Estatus</th>
              <th>Derecho en Pesos</th>
              <th>Vigencia del Cobro</th>
              <th>Sanción en Pesos</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
           <!---- <tr v-for="dato in data_">
              <td>{{dato.folio_id}}</td>
              <td>{{dato.licencia}}</td>
              <td>{{dato.nombre_comercial}}</td>
              <td>{{dato.rfc}}</td>
              <td>{{dato.municipio}}</td>
              <td><span v-bind:class="[dato.estatus==='VIGENTE' ? 'btn-success' : dato.estatus==='REFERENCIA ACTIVA' ? 'btn-warning' : dato.estatus==='PAGADO' ? 'label-default' : 'label-danger']" class="label  btn-circle">{{dato.estatus}} </span> </td>
              <td>{{dato.derecho_en_pesos}}</td>              
              <td>{{dato.vigencia_del_cobro}}</td>
              <td>{{dato.sancion_en_pesos}}</td>
              <td>{{dato.total}}</td>               
              <td ></td>
            </tr> -->
          </tbody>
          
        </table>
    </div>
  </template>
  <script>
    export default {
      name:"TableSample",
      props: {id:'',data_:[],lengthMenu:[]},
      mounted () {
        //$('#'+this.id).DataTable();
        //TableManaged2.init2();
        this.createTable();
        window.myComponentInstance = this;
      },      
      methods:{
        acciontablaguardar(dato){
          //console.log(dato);
          this.$emit('guardar', dato);
        },
        createTable(){
          $('#sample_2').DataTable( {
            "data": this.data_,
              "columns": [
                {"data": "folio_id"},
                {"data": "licencia"},
                {"data": "nombre_comercial"},
                {"data":"rfc"},
                {"data":"municipio"},
                {"data":"email",
                  "render": this.spanEstatus
                },
                {"data":"derecho_en_pesos"},
                {"data":"vigencia_del_cobro"},
                {"data":"sancion_en_pesos"},
                {"data":"total"},
                {"render": this.btnEditar
                },              
              ]
          });
        },
        btnEditar( data, type, row, meta){
          return "<a class='btn btn-icon-only btn-circle green' data-toggle='modal' href='#portlet-edit' title='Editar Registro'  onclick='acciontablaguardar("+JSON.stringify(row)+")'><i class='fa fa-edit'></i></a>";
        },
        spanEstatus( data, type, row, meta){
          let status=row.estatus==='VIGENTE' ? 'btn-success' : row.estatus==='REFERENCIA ACTIVA' ? 'btn-warning' : row.estatus==='PAGADO' ? 'label-default' : 'label-danger'
          return '<span class="label  btn-circle '+status+'" class="">'+row.estatus+' </span>';
        }
      }   
    }
  </script>