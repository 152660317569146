<template>
  <div v-bind:id="id" class="modal fade " tabindex="-1" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <slot name="modal-header"> 

            </slot>
          </div>
          <div class="modal-body">
            <slot name="modal-body"> 

            </slot>
          </div>
          <div class="modal-footer">
            <slot name="modal-footer"> 

            </slot>
          </div>
        </div>
      </div>
    </div>
</template>
<script >
  export default {
    name:"ModalConfirmation",
    props: ['id'],
  }
</script>