<template>
    <div class="container col-md-12">
      <div class="portlet light bordered  blockui_sample_3_2_element">
        <div class="portlet-title">
          <div class="actions">
            <a data-toggle="modal" v-on:click="rdownload()" class="btn btn-circle btn-default border"><i class="fa fa-file-excel-o"></i> Descargar Reporte </a>
            <a class="btn btn-circle btn-icon-only btn-default fullscreen" href="javascript:;">
            </a>
          </div>
          <div class="caption">
            <span class="caption-subject bold uppercase"> <i class="fa fa-bar-chart-o"></i> Reportes</span>
          </div>
           <div class="caption col-md-12">   
            <div class="row">
              <div class='col-md-2'> 
                <div class='form-group'>   
                  <select2 v-if="renderSelect" class="form-control" :options="options" v-model="s_fecha" id="id_fecha"> 
                    <option ></option>
                    <option v-for="e in options_year" :value="e.id">{{e.nombre}}</option>                   
                  </select2>
                </div>
              </div>
              <div class="col-md-2"> <button class="btn green" v-on:click="obtenerR()"> Buscar</button> </div>
            </div>
          </div>          
        </div>
        <div class="portlet-body">
          <div class="tabbable-line boxless tabbable-reversed">
            <ul class="nav nav-tabs" id="d_tabs">
              <li >
                <a href="#tab_0" data-toggle="tab" id="tab_b">Tabla Anual</a>
              </li>
              <li class="active">
                <a href="#tab_1" data-toggle="tab" v-on:click="changeTipo()">Grafica Anual</a>
              </li>
              <li >
                <a href="#tab_2" data-toggle="tab" v-on:click="changeTipoE()">Grafica Historial</a>
              </li>
            </ul>
            <div class="tab-content" id="c_tabs">
              <div class="tab-pane" id="tab_0">
                <div class="portlet-body">
                  <div class="row">
                    <!--
                      <tableSample :columns_data="columns_data" :lengthMenu="lengthMenu" :data_="data_" :id="'sample_2'" v-if="renderTable"/>
                    -->
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>Mes</th>
                            <th>Transacciones Egobierno</th>
                            <th>Monto Total Egobierno</th>
                            <th>Transacciones Repositorio</th>
                            <th>Monto Total Repositorio</th>
                            <th>Total Transacciones</th>
                            <th>Monto Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="d in data_" >
                            <td>{{ d.mes }}</td>
                            <td>{{ d.transE }}</td>
                            <td>{{ d.montoE }}</td>
                            <td>{{ d.trans }}</td>
                            <td>{{ d.monto }}</td>
                            <td>{{ d.total_trans }}</td>
                            <td>{{ d.total_monto }}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>TOTALES</th>
                            <th>{{ calcularTotal("transE",0) }}</th>
                            <th>{{ calcularTotal("montoE",1) }}</th>
                            <th>{{ calcularTotal("trans",0) }}</th>
                            <th>{{ calcularTotal("monto",1) }}</th>
                            <th>{{ calcularTotal("total_trans",0) }}</th>
                            <th>{{ calcularTotal("total_monto",1) }}</th>
                          </tr></tfoot> 
                      </table>
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="tab-pane active" id="tab_1">
                <div class="portlet-body">
                  <div class="row">
                    <div class='col-md-2'>
                      <div class='form-group'>
                        <select2 class="form-control" :options="options" v-model="title_c" @eventchange="changeTipo">
                          <option value="Transacciones" selected>Transacciones</option>
                          <option value="Monto Total">Monto Total</option>
                        </select2>
                      </div>
                    </div>
                  </div>
                  <div class="row">       
                    <line-chart v-if="loadedT" :chartdatas="chartdatas"/>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab_2">
                <div class="portlet-body">
                  <div class="row">
                    <div class='col-md-2'>
                      <div class='form-group'>
                        <select2 class="form-control" :options="options" v-model="title_e" @eventchange="changeTipoE">
                          <option value="Transacciones" selected>Transacciones</option>
                          <option value="Monto Total">Monto Total</option>
                        </select2>
                      </div>
                    </div>
                  </div>
                  <div class="row">       
                    <line-chart v-if="loadedE" :chartdatas="chartdatasE"/>
                  </div>
                </div>
              </div>              
            </div>
          </div>  
        </div>
      </div>
    </div>
  </template>  
  <script>
  import axios from 'axios'
  import select2 from './General/Select.vue'
  import tableSample from './General/TableSample.vue'
  import VCalendar from 'v-calendar'
  import LineChart from './Chartsjs/LineChart.vue'
  export default {
    name: 'ReporteEstadistica',
    components: { tableSample,select2,tableSample,VCalendar,LineChart},
    data(){return{
        s_fecha:"",
        renderTable:false,
        renderSelect:false,
        columns_data: [],
        data_: [],
        options:[],
        options_year:[],
        lengthMenu:[],
        loadedT: false,
        loadedE: false,
        chartdatas: [],
        chartdatasE: [],
        data_c:[],
        data_e:[],
        title_c:"Transacciones",
        title_e:"Transacciones",
      }
    },
    methods:{ 
        firtsDate(){
          let date = new Date();
          let end_=date.getFullYear()
          for (let index = end_; index > 2007; index--) {
            const element = {id:index,nombre:index}
            this.options_year.push(element)
          }          
        },
        obtenerR(){ 
          this.renderTable = false        
          this.loagingC(true)
          this.data_=[]
         
          axios.post(`${process.env.APP_URL}/reporte-consulta`,{
            years: this.s_fecha
          })
          .then((response)=>{
            this.renderTable = true
            let datos=[]
            for (let index = 1; index < 13; index++) {
              let merged={}
              let operacion=this.filterResponse(response.data.operacion,index,"mes",index,this.s_fecha)
              let egobierno=this.filterResponse(response.data.egobierno,index,"mesE",index,this.s_fecha)
              merged = Object.assign(operacion, egobierno);
              datos.push(merged)
            }
            datos.forEach((item,ind)=>{
              let ms=this.fmeses(item.mes)
              let i={
                anio:item.anio,
                mes: ms,
                transE:this.fmoneda(item.transaccionesE,0),
                montoE:this.fmoneda(parseFloat(item.totalE),1),
                trans:this.fmoneda(item.transacciones,0),
                monto:this.fmoneda(parseFloat(item.total),1),
                total_trans:this.fmoneda(parseFloat(item.transacciones) + parseFloat(item.transaccionesE),0),
                total_monto:this.fmoneda(parseFloat(item.total,1)+parseFloat(item.totalE),1)
              }     
            this.data_.push(i)
          })
          this.data_c=[this.createData(response.data.operacion,"mes","operacion",false),this.createData(response.data.egobierno,"mesE","egobierno",false)]
          this.chartdatas=this.findarray(this.data_c,this.title_c)
          this.loagingC(false)
          this.changeTipo()          
        })   
      },
      fmeses(value, tipo, values) {
        return Vue.filter('mesesConvert')(value);
      },
      fmoneda(value, tipo, values) {
        return Vue.filter('chartTick')(value,tipo);
      },
      loagingC(value, index, values) {
        return Vue.filter('loadingCharge')(value);
      },
      rdownload() {        
        let nombre='Reporte-estadistica' + this.s_fecha
        let encabezado='Año,Mes,Transacciones Egobierno,Monto Total Egobierno,Transacciones Repositorio,Monto Total Repositorio,Total Transacciones,Monto Total'
        return Vue.filter('saveCSV')(this.data_,encabezado,nombre);
      },
      filterResponse(data,mes,tipo,mes_,fecha_){
        const filteredList = data.filter((e) => e[tipo] === mes).map((e) => { return e});
        let filtered=filteredList
        if(filteredList.length==0){
          if(tipo=="mesE"){
            filtered={anioE: fecha_,mesE: mes_,transaccionesE: "0",totalE: "0"}
          }else{
            filtered={anio:fecha_,mes: mes_,transacciones: "0",total: "0"}
          }
          return filtered
        }else{
          return filtered[0]
        }        
      },
      obtenerE(){ 
          this.loadedE = false
          this.data_=[]
          axios.get(`${process.env.APP_URL}/reporte-consulta-all`)
          .then((response)=>{
            let resp=response.data
            this.loadedE = true
            let datos=[]
            let lengE= 0
            let countE= 0
            let hiddenE= false
            for(let datas in resp.egobierno){++lengE}
            for(let datas in resp.egobierno){             
              ++countE;
              hiddenE=false
              if(lengE!=countE){ hiddenE=true }
              datos.push(this.createData(resp.egobierno[datas],"mes","egobierno",hiddenE))
            }
            let lengO= 0
            let countO= 0
            let hiddenO= false
            for(let datas in resp.operacion){++lengO}
            for(let datas in resp.operacion){
              ++countO;
              hiddenO=false
              if(lengO!=countO){hiddenO=true }
              datos.push(this.createData(resp.operacion[datas],"mes","operacion",hiddenO))
            }
            this.data_e=datos
            this.chartdatasE=this.findarray(this.data_e,this.title_e)
            this.changeTipoE()
        })   
      },
      createData(datas,mes_,tipo,hidden_){
        let data_la=[]
        let data_to=[]
        let data_tr=[]
        let anio_=0    
        for (let index = 1; index < 13; index++) { 
          let ms=this.fmeses(index)
          data_la.push(ms)
          let filteredList = datas.filter((e) => e[mes_] === index).map((e) => { return e})
           //console.log(filteredList); 
          if(filteredList.length==0){
            data_to.push(0)
            data_tr.push(0)
          }else if(mes_=="mesE"){ 
            anio_=filteredList[0].anioE   
            data_to.push(filteredList[0].totalE)
            data_tr.push(filteredList[0].transaccionesE)            
          }else{
            anio_=filteredList[0].anio    
            data_to.push(filteredList[0].total)
            data_tr.push(filteredList[0].transacciones)
          }
        }   
        let d={data:[],data_label:data_la,data_total:data_to,data_transacciones:data_tr, mes: tipo + " - " +anio_,hide:hidden_}
        return d
      },
      findarray(data,title_c){
        var d=[]
        if(title_c=="Transacciones"){ 
          data.forEach((item,ind)=>{
            Object.assign(data[ind],{"data": item.data_transacciones})
          })
            d=Object.assign(data,{format:"0"})          
        }else{
          data.forEach((item,ind)=>{
            Object.assign(data[ind],{"data": item.data_total})
          })
          d=Object.assign(data,{format:"1"})
        }
        return d
      },
      changeTipo(event) {
        this.loadedT = false
        this.chartdatas=this.findarray(this.data_c,this.title_c)
        let selft=this
        setTimeout(function() {selft.loadedT=true}, 200)    
      },
      changeTipoE(event) {
        this.loadedE = false
        this.chartdatasE=this.findarray(this.data_e,this.title_e)
        let selft=this
        setTimeout(function() {selft.loadedE=true}, 200)    
      },
      calcularTotal(key,tipo_) {
        let totalGeneral = 0;        
        for (let d of this.data_) {
          let total = d[key];
          total=total.replace('$','')
          total=total.replaceAll(',','')
          total=parseFloat(total)
          totalGeneral += total;
        }
        totalGeneral=this.fmoneda(totalGeneral,tipo_)
        return totalGeneral;
      }
    },
    mounted() {
      let date = new Date();
      let end_=date.getFullYear()
      this.s_fecha=end_
      this.renderSelect=true
      this.columns_data= [{title:'Año',data:'anio'},{title:'Mes',data:'mes'},{title:'Transacciones Egobierno',data:'transE' },{title:'Monto Total Egobierno',data:'montoE'},{title:'Transacciones Repositorio',data:'trans' },{title:'Monto Total Repositorio',data:'monto'},{title:'Total Transacciones',data:'total_trans' },{title:'Monto Total',data:'total_monto'}]
      this.lengthMenu= [[15, 25, 50, -1],[15, 25, 50, 'All']]
      this.firtsDate()
      this.obtenerR()
      this.title_c="Transacciones"
      this.obtenerE()
    }  
  }
  </script>