<template>
  <div >
    <portlet :clase="'container col-md-12'">
      <template slot="actions"> </template>
      <template slot="caption">
        <div class="row"> 
          <div class='col-md-6'>          
            <span class="caption-subject bold uppercase"><i class="fa fa-search"></i> Filtros</span>          
          </div> 
        </div>  
      </template>
      <template slot="caption2">
        <div class="row">	
          <div class="col-md-3 col-ms-12">
            <div class="form-group">
              <label >Folio</label>
              <input type="text" name="folio" v-model="folio" class="form-control" placeholder="Ingresa el folio.." autocomplete="off"> 
            </div>
          </div>	          
          <div class="col-md-3 col-ms-12">
            <div class="form-group">
              <label >Tipo Busqueda</label>
              <select2 class="form-control" :options="options" v-model="tipo_busqueda">
                <option v-for="b in data_tipo" :value="b.value">{{b.name}}</option>
              </select2>
            </div>
          </div>		            
          <div class="col-md-1 col-ms-12">
            <div class="form-group">
              <label>&nbsp;</label>
              <button type="button" class="btn green enableBtn" id="btnbuscar" v-on:click="filterResponse()" >Buscar</button>
            </div>
          </div>            
        </div> 
      </template>
    </portlet>
    <portlet :clase="'container col-md-12 blockui_sample_3_2_element'">
      <template slot="actions"></template>
      <template slot="caption">
        <div class="row"> 
          <div class='col-md-6'>          
            <span class="caption-subject bold uppercase"> <i class="fa fa-cogs"></i> Registros</span>          
          </div> 
        </div>  
      </template>      
      <template slot="body">         
        <div class="row">
          <!--<tableSample :columns_data="columns_data" :lengthMenu="lengthMenu" :data_="data_" :id="'sample_2'" v-if="renderTable"/>-->
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>FOLIO </th>
                  <th>FOLIO TRAMITE</th>
                  <th>FOLIO ID</th>                  
                  <th>LICENCIA</th>
                  <th>TITULAR</th>
                  <th>RFC</th>
                  <th>DOMICILIO</th>
                  <th>MUNICIPIO</th>
                  <th>NOMBRE COMERCIAL</th>
                  <th>INE</th>
                  <th>ANUENCIA</th>
                  <th>LICENCIA A REFRENDAR</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dato in data_">
                  <td>{{dato.id_transaccion_motor}}</td>
                  <td>{{dato.id_tramite_motor}}</td>
                  <td>{{dato.folio_id}}</td>                  
                  <td>{{dato.licencia}}</td>                      
                  <td>{{dato.titular}}</td>                      
                  <td>{{dato.rfc}}</td>
                  <td>{{dato.domicilio}}</td>
                  <td>{{dato.municipio}}</td>
                  <td>{{dato.nombre_comercial}}</td>             
                  <td style="text-align: center;"><a class="btn btn-icon-only btn-circle green" data-toggle="modal" href="#portlet-doc" title="Editar Registro"  @click="mostrarpdf(dato,'ine',dato.ine)"><i class="fa fa-file-pdf-o"></i></a></td>
                  <td style="text-align: center;"><a class="btn btn-icon-only btn-circle green" data-toggle="modal" href="#portlet-doc" title="Editar Registro"  @click="mostrarpdf(dato,'anuencia',dato.anuencia)"><i class="fa fa-file-pdf-o"></i></a></td>
                  <td style="text-align: center;"><a class="btn btn-icon-only btn-circle green" data-toggle="modal" href="#portlet-doc" title="Editar Registro"  @click="mostrarpdf(dato,'licencia_refrendar',dato.licencia_refrendar)"><i class="fa fa-file-pdf-o"></i></a></td>
                </tr>
              </tbody>                         
            </table>
          </div>                    
        </div>
      </template>
    </portlet>
    <modal :id="'portlet-doc'" :modal="'modal-lg'">      
        <template slot="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" v-on:click="cerrarModal()"></button>
            <h4 class="modal-title"><i class="fa fa-file-pdf-o"></i> Archivo Cargado</h4>
        </template>
        <template slot="modal-body" >           
          <div class="row blockui_sample_3_2_element">
            <div class="col-md-12 ">           
              <iframe :src="iframeSrc" @load="load" v-show="loaded" ref="frame" type="application/pdf" width="100%" height="500px" title="Archivo pdf" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div> 
          </div>
          <hr>
          <div class="row">               
            <div class="col-md-6"> 
              <div class="fileinput fileinput-new" data-provides="fileinput">                       
                <span class="btn green btn-file">
                <span class="fileinput-new">
                <i class="fa fa-plus"></i>&nbsp; Adjuntar o Reemplazar Archivo</span>
                <span class="fileinput-exists">
                <i class="fa fa-exchange"></i>&nbsp; &nbsp;Cambiar Archivo </span>
                <input type="file" name="file" accept="application/pdf" id="file" v-on:change="handleFileUpload( $event )">
                </span>
                <button type="submit" class="btn blue fileinput-exists enableBtn" v-on:click="uploadFile()"><i class="fa fa-check"></i> Guardar</button>
                <span class="fileinput-filename" style="text-overflow: ellipsis;width: 240px;overflow: hidden; white-space: nowrap;">
                </span>&nbsp; <a href="javascript:;" class="close fileinput-exists" data-dismiss="fileinput"id="delFilePDF" v-on:click="cancelaArchivo()">
                </a>          
              </div>
            </div>
            <div class="col-md-6"> 
              <div class="form-group" id="addurlfile">
                <label style='color: #cb5a5e;'>Descargar Archivo:</label>
                 <a :href="href_file" title="Descargar Archivo" >{{fname_download }}...<i class="fa fa-download blue"></i>
                </a>
              </div>
            </div>
        </div>
        </template>
        <template slot="modal-footer">
          <button type="button" data-dismiss="modal" class="btn default" v-on:click="cerrarModal()">Cerrar</button>
        </template>
      </modal>
  </div>
</template>  
<script>
import axios from 'axios'
import select2 from './General/Select.vue'
import portlet from './General/Portlet.vue'
import modal from './General/Modal.vue'
import modalconfirmation from './General/ModalConfirmation.vue'
import tableSample from './General/TableSample.vue'
import VCalendar from 'v-calendar'
export default {
  name: 'ReporteEstadistica',
  components: {axios,select2,portlet,modal,modalconfirmation,tableSample,VCalendar},
  data(){return{
    folio:'',
    iframeSrc:'',
    loaded:'',
    iframeSrcOld:'',
    href_file:'',
    fname_download:'',
    id_transaccion_motor:'',
    ticket_id:'',
    file:'',
    tipo:'',
    data_:[],
    data_tipo:[
      {"value":"folio_pago","name":"Folio de Pago"},
      {"value":"fse","name":"FSE"},
      {"value":"tramite_id","name":"Tramite ID"},
    ],
    tipo_busqueda:'folio_pago',
    options: [],
    }
  },
  methods:{ 
    handleFileUpload(event){
      this.file = event.target.files[0];
      console.log(this.file);
      if(this.file==undefined){
        this.cancelaArchivo();
      }else{
       this.iframeSrc= URL.createObjectURL(this.file);
      }
    },
    filterResponse(){
      this.loagingC(true);
      this.data_=[];
      axios.post(`${process.env.APP_URL}/search-alcoholes`,{
        folio: this.folio,
        tipo: this.tipo_busqueda})
      .then((response)=>{          
        this.data_=response.data.response;
        //this.notifications(response.data.status,response.data.message);
        this.loagingC(false);
      }).catch(error=>{
        this.notifications('400',"Registros no encontrados");
        this.loagingC(false);
       console.log(error)
      })
    },
    fileResponse(){
      this.loagingC(true);
      axios.post(`${process.env.APP_URL}/view-alcoholes-file`,{
        attach: this.href_file})
      .then((response)=>{          
        const blob = this.dataURItoBlob(response.data);
        let uri=URL.createObjectURL(blob);
        this.iframeSrc= uri;
        this.iframeSrcOld=uri;
        this.loagingC(false);
      }).catch(error=>{
        this.notifications('400',"Archivo no encontrado");
        this.iframeSrc="";
        this.iframeSrcOld="";
        console.log(error);
        this.loagingC(false);
      })
    },
    cancelaArchivo(){
      this.iframeSrc=  this.iframeSrcOld;
    },
    cerrarModal(){
      this.href_file='';      
      this.fname_download='';
      this.tipo='';
      this.ticket_id='';
      this.id_transaccion_motor='';
      this.iframeSrc='',
      this.iframeSrcOld='',
      this.loaded=true;
      this.fileClear();
      this.filterResponse();
    },
    mostrarpdf(data,tipo,link){
      this.href_file=link;
      this.tipo=tipo;
      this.ticket_id=data.ticket_id;
      this.id_transaccion_motor=data.id_transaccion_motor;
      let name=link.split("/");
      this.fname_download=name[name.length-1];
      this.fileResponse();
      this.loaded=true;
    },
    load: function(){
    	this.loaded = true;
    },
    uploadFile(){
      if(this.file.length==0){this.notifications("400","Archivo, requerido!"); return false;}
        let formData = new FormData();
        formData.append('filepdf', this.file);
        formData.append('ticket_id', this.ticket_id);
        formData.append('id_transaccion_motor', this.id_transaccion_motor);
        formData.append('file_name', this.fname_download);
        formData.append('tipo', this.tipo);
        axios.post(`${process.env.APP_URL}/upload-alcoholes-file`,
        formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response)=>{
            this.notifications(response.data.status,response.data.message);
            this.fname_download=response.data.file_name;
            this.href_file=response.data.url;
            this.iframeSrcOld=  this.iframeSrc;
            this.fileClear();          
        })
        .catch(error=>{
            console.log(error);
        }
      );
    },    
    fmoneda(value, tipo, values) {
      return Vue.filter('chartTick')(value,tipo);
    },
    notifications(Code,Message){
      if(Code=="200"){
          Command: toastr.success(Message, "Notifications");
      }else{
          Command: toastr.warning(Message, "Notifications");
      }
    },
    fileClear(){
      $("#delFilePDF").click();
    },
    dataURItoBlob(dataURI) {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'application/pdf'});
      return blob;
    },
    loagingC(value, index, values) {
      return Vue.filter('loadingCharge')(value);
    }
  },
  mounted() {
    
  }  
}
</script>