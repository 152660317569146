
<script>
  import { Bar } from 'vue-chartjs'

export default {
  name:"BarChart",
  props:{
    chartdatas:[]

  },
  extends: Bar,
  data(){
      return{
        colorAr:[],
        chartdata: {
        
        labels: this.dataLabel(),
        datasets: this.dataFormat()
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
         callbacks: {
            label: this.fTooltipsCallbacks
          }
        },
        scales: {
          yAxes: [{
            ticks: {
               callback: this.fTickscallback
            }
          }]
        }
      }
    }
  },

  mounted () {
    this.colorsd();
    this.renderChart(this.chartdata, this.options)
    
  },
  methods:{
    dynamicColors(){
      var r = Math.floor(Math.random() * 255);
      var g = Math.floor(Math.random() * 255);
      var b = Math.floor(Math.random() * 255);
      var a = 0.4;
      return "rgb(" + r + "," + g + "," + b + "," + a + ")";
    },colorO(){
      return  this.colorAr;
    },
    dataFormat(){
      var data=[]
       this.chartdatas.forEach((item,ind)=>{
         data.push({
            label: item.mes,
            borderWidth: 2,
            backgroundColor: this.colorO,
            pointBackgroundColor : this.colorO,
            borderColor: this.colorO,
            data: item.data
          })
       })
      return data
    },
    colorsd(){
      if(this.chartdatas.length>0){
        for (var i in this.chartdatas[0].data_label) {
          this.colorAr.push(this.dynamicColors());          
        }
      }
    },
    fTickscallback(value, index, values) {
      return Vue.filter('chartTick')(value,this.chartdatas.format);
    },
     fTooltipsCallbacks(t, d) {
      return Vue.filter('chartTooltips')(t,d,this.chartdatas.format);
    },dataLabel(){
      var labels=""
      if(this.chartdatas.length>0){
        labels=this.chartdatas[0].data_label
      }
        return labels
    }
  }
}
</script>