<template>
  <div>
    <modal :id="'portlet-editar'" :modal="'modal-lg'">      
      <template slot="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" v-on:click="cerrarModal()"></button>
          <h4 class="modal-title"><i class="fa fa-file-pdf-o"></i> Registro de usuario</h4>
      </template>
      <template slot="modal-body" >           
        <div class="row">
          <div class="col-md-12">
            <div class="form"><h4 class="form-section"><strong>Datos Generales</strong></h4></div>
          </div>
          <div class="col-md-12">        
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="numero_empleado">Número de Empleado</label>
                <input type="text" class="form-control uppercase-text" v-model="formulario.numero_empleado" name="numero_empleado" autocomplete="off"placeholder="Número de Empleado" v-on:input="validaNoEmpleado()" :disabled="inputNoEmpleado==true" @keypress="validaLetrasNumeros($event)">
                <span class="help-block" style="color: red !important;">{{ mensaje_no_empleado }}</span>
              </div>
            </div>
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="nombre">Nombre(s)</label>
                <input type="text" class="form-control uppercase-text" @keypress="validaLetras($event)" v-model="formulario.name" name="nombre" autocomplete="off"placeholder="Nombres">
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="apellido_paterno">Apellido Paterno</label>
                <input type="text" class="form-control uppercase-text"@keypress="validaLetras($event)" v-model="formulario.fathers_surname" name="apellido_paterno" autocomplete="off"placeholder="Apellido Paterno">
              </div>
            </div>
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="apellido_materno">Apellido Materno</label>
                <input type="text" class="form-control uppercase-text" @keypress="validaLetras($event)" v-model="formulario.mothers_surname" name="apellido_materno" autocomplete="off"placeholder="Apellido Materno">
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="rfc">RFC</label>
                <input type="text" class="form-control  uppercase-text" @keypress="validaLetrasNumeros($event)" v-model="formulario.rfc" name="rfc" autocomplete="off" placeholder="RFC"  maxlength="13"  v-on:input="rfcValida()">
                <span  class="help-block" style="color:red;">{{ rfc_valid }}</span>
              </div>
            </div>
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="curp">CURP</label>
                <input type="text" class="form-control  uppercase-text" @keypress="validaLetrasNumeros($event)" v-model="formulario.curp" name="curp" autocomplete="off" placeholder="CURP"  maxlength="18" v-on:input="curpValida()">
                <span class="help-block" style="color:red;">{{ curp_valid }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="dependencia">Dependencia</label>
                <input type="text" class="form-control  uppercase-text"  @keypress="validaLetras($event)" v-model="formulario.dependencia" name="rfc" autocomplete="off" placeholder="Dependencia" >
              </div>
            </div>
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="puesto">Puesto</label>
                <input type="text" class="form-control  uppercase-text"  @keypress="validaLetras($event)" v-model="formulario.puesto" name="puesto" autocomplete="off" placeholder="Puesto">
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="telefeno">Número de Teléfono</label>
                <input type="text" class="form-control " v-on:input="formatoTelefono()" @keypress="validaNumero($event)"v-model="formulario.phone" name="telefeno" autocomplete="off"placeholder="Número de Teléfono"  maxlength="14">
              </div>
            </div>
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="email">Correo Electrónico</label>
                <input type="text" class="form-control"  @keypress="validaCorreo($event)"  v-model="formulario.email" name="email" autocomplete="off"placeholder="Correo Electrónico"  v-on:input="emailValida()" >
                <span class="help-block" style="color:red;">{{ email_valid }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="usuario">Usuario</label>&nbsp; &nbsp;<a  class=" popovers"  data-trigger="hover" data-placement="top" data-content="El usuario debe de estar compuesto por numeros(puede contener letras minúsculas) y ser al menos 8 caracteres." data-original-title="Información"><i class="fa fa-question-circle"></i></a> 
                <input type="text" class="form-control" v-model="formulario.username" name="usuario" autocomplete="off"placeholder="Usuario">
              </div>
            </div>
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="password">Contraseña</label>&nbsp; &nbsp;<a  class=" popovers"  data-trigger="hover" data-placement="top" data-content="La contraseña debe de estar compuesta por una minúscula, una mayúscula, un número y contener un mínimo de 8 caracteres." data-original-title="Información"><i class="fa fa-question-circle"></i></a> 
                <a class="pull-right" v-on:click="gPasswordPerf()">Generar</a>
                <div class="input-group input-icon right">
                    <span class="input-group-addon default"><i class="fa fa-key"></i></span> 
                    <i  id="pass1" v-bind:class="[eyes_change ? 'fa fa-eye-slash' : 'fa fa-eye']" v-on:click="onechange1()"  style="cursor:pointer;color: black;"></i>
                    <input :type="[eyes_change ? 'password' : 'text']" name="password"id="password" autocomplete="new-password" class="form-control" placeholder="Ingresa la Contraseña" v-model="formulario.password">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form"> <h4 class="form-section"><strong>Plataforma</strong></h4> </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="plataforma">Plataforma</label>
                <select2 class="form-control" :options="options" v-model="formulario.plataforma" @eventchange="changePlataforma" :disabled="inputDisable==true">
                  <option  :value="'0'"> --- Selecciona ---</option>
                  <option  :value="'TRAMITES.NL'"> TRAMITES.NL</option>
                  <option  :value="'ADMINISTRADOR DE LA TESORERIA'">ADMINISTRADOR DE LA TESORERIA</option>
                </select2>
              </div>
            </div>
            
          </div>
          <div class="col-md-12"  v-if="plataforma_portal">
            <div class="form"> <h4 class="form-section"><strong>TRAMITES.NL  <a v-if="btn_quitar" class="btn btn-icon-only btn-circle red text-right" href="#portlet-delete"  data-toggle="modal" v-on:click="eliminarPlataforma('TRAMITES.NL')"><i class="fa fa-trash" ></i></a></strong></h4> </div>
          </div>
          <div class="col-md-12" v-if="plataforma_portal">
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="rol_usuario">Rol Usuario</label>
                <select2 class="form-control" :options="options" v-model="formulario.role_id">
                  <option  :value="'0'"> --- Selecciona ---</option>
                  <option v-for="b in data_roles" :value="b.id">{{b.description}}</option>
                </select2>
              </div>
            </div>
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="comunidad">Comunidad</label>
                <select2 class="form-control" :options="options" v-model="formulario.config_id">
                  <option  :value="'0'"> --- Selecciona ---</option>
                  <option v-for="b in data_comunidad" :value="b.id">{{b.descripcion}}</option>
                </select2>
              </div>
            </div>
          </div>
          <div class="col-md-12"  v-if="plataforma_admin">
            <div class="form"> <h4 class="form-section"><strong>ADMINISTRADOR DE LA TESORERIA   <a v-if="btn_quitar" class="btn btn-icon-only btn-circle red text-right" href="#portlet-delete"  data-toggle="modal" v-on:click="eliminarPlataforma('ADMINISTRADOR DE LA TESORERIA')"><i class="fa fa-trash"></i></a> </strong></h4></div>
          </div>
          <div class="col-md-12"  v-if="plataforma_admin">
            <div class="col-md-6 col-ms-12">
              <div class="form-group">
                <label for="plataforma">Rol Usuario</label>
                <select2 class="form-control" :options="options" v-model="formulario.is_admin">
                  <option :value="'0'"> Usuario</option>
                  <option :value="'1'">Administrador</option>
                </select2>
              </div>
            </div>
          </div>
          <div class="col-md-12"  v-if="plataforma_admin">
            <div class="panel-group accordion scrollable" id="accordion2">
              <div class="panel panel-warning">
                <div class="panel-heading">
                  <h4 class="panel-title">
                  <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion2" href="#collapse_2_2" aria-expanded="true">Menu </a>
                  </h4>
                </div>
                <div id="collapse_2_2" class="panel-collapse in">
                  <div class="panel-body" style="height:310px;">
                    <div class="col-md-6" style="height:290px; overflow-y:auto;overflow-x:auto;">
                      <h4>Menu desplegable:</h4>
                      <v-treeview  open-all selectable v-model="selected" :items="items"></v-treeview>
                    </div>
                    <div class="col-md-6" style="height:290px; overflow-y:auto;">
                      <div class="form-group" style="padding-left:16px;">
                        <h4>Seleccionados:</h4>
                         <input type="text" class="form-control" placeholder="Busqueda" v-model="busqueda" v-on:keyup="buscarLi()">
                         <span class="help-block">&nbsp;</span>
                        <ul style="padding-left:16px;"><li class="lista" v-for="fm in data_user_menu">{{ fm.title}}</li></ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="plataforma_admin">
            <span class="help-block">&nbsp; </span>
            <div class="col-md-5" v-if="conjunto">
              <div class="form-group">
                <label class="control-label">Conjunto de trámites:</label>
                <multiselect v-model="formulario.permisos" :options="data_permisos" :searchable="true" track-by="id" label="nombre" :multiple="true" placeholder="Selecciona una opción" class="custom-multiselect"></multiselect>
            </div>
            </div>
          </div>
          <div class="col-md-12 text-center" v-if="hidden_agregar">
              <div class="form-group">
                <span class="help-block">&nbsp;</span>
                <button type="button"  class="btn blue " v-on:click="agregarPlataforma()"><i class="fa fa-check"></i> Agregar</button>
              </div>
            </div>
          <div class="col-md-12">
            <div class="form"> <h4 class="form-section"><strong>Documentos Adjuntos</strong></h4> </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-6 col-ms-12">
              <btnFile :textoInput="'Adjuntar Archivo'" :textoChange ="'Cambiar Archivo'" @cancelaArchivo="cancelaArchivo" @inputFile="inputFile"  :accept="'application/pdf'" :guardar="false"></btnFile>
            </div>
          </div>
          <div class="col-md-12">
            <hr>
            <div class="col-md-12 col-ms-12 text-center">
              <button type="button"  class="btn blue" v-on:click="saveUserUpdate()"><i class="fa fa-check"></i> Guardar</button>
            </div>
          </div>
        </div>
      </template>
      <template slot="modal-footer">
        <button type="button" data-dismiss="modal" class="btn default" v-on:click="cerrarModal()">Cerrar</button>
      </template>
    </modal>
    <modalconfirmation :id="'portlet-delete'" >      
      <template slot="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
        <h4 class="modal-title">Confirmar</h4>
      </template>
      <template slot="modal-body">
        Al dar clic en Aceptar se eliminará el acceso del usuario a <label style="color: #cb5a5e;">{{ delete_plataforma }}</label>
      </template>
      <template slot="modal-footer">
        <button type="button" data-dismiss="modal" class="btn default">Cancelar</button>
        <button type="button" data-dismiss="modal" class="btn green" v-on:click="quitarPlataforma()"><i class="fa fa-check"></i> Aceptar</button>
      </template>
    </modalconfirmation>
  </div>
</template>
<script>

  import axios from 'axios'
  import select2 from '../General/Select.vue'
  import btnFile from '../General/btnFile.vue'
  import modal from '../General/Modal.vue'
  import modalconfirmation from '../General/ModalConfirmation.vue'
  import multiselect from 'vue-multiselect'
  //import '@mdi/font/css/materialdesignicons.min.css';
  import { parseJSON } from 'jquery'
import { forEach } from 'lodash';
 
  //this.phone.replace(/\D/g, '')
  export default {
    data(){
      return{
        items: [],
        eyes_change:true,
        options:[],
        plataforma_admin:false,
        plataforma_portal:false,
        conjunto:false,
        formulario:{
          id:'',
          plataforma:'0',
          numero_empleado:'',
          name:'',
          fathers_surname:'',
          mothers_surname:'',
          rfc:'',
          curp:'',
          dependencia:'',
          puesto:'',
          phone:'',
          email:'',
          username:'',
          password:'',
          is_admin:'0',
          menu:[],
          config_id:'0',
          permisos:[],
          role_id:'0',
          user_portal:null,
          status:'0',
        },
        filePdf:'',
        data_roles:[],
        data_permisos:[],
        data_comunidad:[],
        data_user_menu:[],
        data_menu:[],
        rfc_valid:'',
        curp_valid:'',
        hidden_agregar:false,
        email_valid:'',
        selected:[],
        open:[1],
        busqueda:'',
        inputDisable:false,
        inputNoEmpleado:false,
        btn_quitar:false,
        delete_plataforma:'',
        delete_id:'',
        delete_status:'1',
        usuario_descripcion:'',
        status_descripcion:'',
        mensaje_no_empleado:''
      }
    },
    components: {select2,btnFile,modal,multiselect,modalconfirmation},
    name:"TableSampleRecibo",
    props: {id:'',data_:[],lengthMenu:[]},
    mounted () {
      this.catalogos();
      //this.catalogos_permisos();
    },      
    methods:{
      eliminarPlataforma(plataforma){
        this.delete_plataforma=plataforma;
        this.usuario_descripcion=this.formulario.email;
        this.status_descripcion='';
        if(this.delete_plataforma=="TRAMITES.NL"){
          this.delete_id=this.formulario.user_portal.id;
          this.delete_status=this.formulario.user_portal.status;
        }else{
          this.delete_id=this.formulario.id;
          this.delete_status=this.formulario.status;
        }
        if(this.delete_status=='1'){
          this.status_descripcion='Desactivar';
        }else{
          this.status_descripcion='Quitar';
        }
      },
      quitarPlataforma(){
        this.btn_quitar=false;
        this.inputDisable=false;
        this.hidden_agregar=true;
        if(this.delete_plataforma=="TRAMITES.NL"){
          this.formulario.plataforma="ADMINISTRADOR DE LA TESORERIA";
          this.plataforma_portal=false;
        }else{
          this.formulario.plataforma="TRAMITES.NL";
          this.plataforma_admin=false;
        }
        if(this.delete_id =='0' ||  this.delete_status=='0'){
          return false;
        }else{
          this.confirmarStatus();
        }
      },
      confirmarStatus(){
        
        axios.post(`${process.env.APP_URL}/status-usuarios`,{
          id:this.delete_id,
          status:this.delete_status,
          plataforma:this.delete_plataforma
        })
        .then((response)=>{ 
          this.notifications(response.data.code,response.data.message);
          this.loadTable();
        })
        .catch(error=>{
            console.log(error);
            this.carga_archivo=true;              
        });
      },
      validaNoEmpleado(){
        this.mensaje_no_empleado='';
        let _numero_empleado=this.formulario.numero_empleado.replace(/ /g, '');
        if(this.data_.numero_empleado==_numero_empleado){
          return;
        }
        if(_numero_empleado.length==0){return;}
        axios.post(`${process.env.APP_URL}/valida-no-empleado`,{
          numero_empleado: _numero_empleado,
        })
        .then((response)=>{ 
          //this.notifications(response.data.code,response.data.message);
          if(response.data.code=="400"){
            this.mensaje_no_empleado=response.data.message;
          }else{
            this.mensaje_no_empleado='';
          }
        })
        .catch(error=>{
            console.log(error);
            this.carga_archivo=true;              
        });
      },
      agregarPlataforma(){
        //if(this.formulario.email.length==0 || this.email_valid.length>0) { this.notifications("400","Correo, requerido!");return true;}
        if(this.formulario.plataforma=="0"){ this.notifications("400","Plataforma, requerido!");return true;}
        this.hidden_agregar=false;
        this.inputDisable=true;
        this.btn_quitar=true;
        if(this.formulario.plataforma=="TRAMITES.NL"){
          this.formulario.plataforma="ADMINISTRADOR DE LA TESORERIA";
         setTimeout(() => {
          this.plataforma_portal=true;
         }, 300); 
        }else{
          this.plataforma_portal=true;
        }
      },
      saveUserUpdate(){
        if(typeof(this.data_.id)==='undefined'){
          this.saveUser();
        }else{
          this.updateUser();
        }
      },
      validaciones(tipo){
        if(this.formulario.numero_empleado.length==0){ 
          this.notifications("400","Formato Número de Empleado, requerido!");return true;}
        this.formulario.numero_empleado=this.formulario.numero_empleado.padStart(6,0);
        if(this.formulario.numero_empleado.length>8 && !/[0-9]/.test(this.formulario.numero_empleado)){ 
          this.notifications("400","Formato Número de Empleado Máximo 8 caracteres, requerido, requerido!");return true;}
        if(this.formulario.numero_empleado.length>8 && !/[^A-Za-zÑñ0-9]/g.test(this.formulario.numero_empleado)){ 
            this.notifications("400","Formato Número de Empleado Máximo 8 caracteres, requerido!");return true;}
        if(this.formulario.numero_empleado.length>8 && /[A-ZÑ]/.test(this.formulario.numero_empleado)){ 
          this.notifications("400","Formato Número de Empleado Máximo 8 caracteres, requerido, requerido!");return true;}

        //if(this.formulario.numero_empleado.length==0 || this.mensaje_no_empleado.length>0) { this.notifications("400","Numero empleado, requerido!");return true;}  
        if(this.formulario.name.length==0) { this.notifications("400","Nombre, requerido!");return true;}  
        if(this.formulario.fathers_surname.length==0) { this.notifications("400","Apellido Paterno, requerido!");return true;}  
        if(this.formulario.mothers_surname.length==0) { this.notifications("400","Apellido Materno, requerido!");return true;}  
        if(this.formulario.rfc.length<12 || this.rfc_valid.length>0) { this.notifications("400","RFC, requerido!");return true;}  
        if(this.formulario.curp.length<18 || this.curp_valid.length>0) { this.notifications("400","CURP, requerido!");return true;}  
        if(this.formulario.phone.length==0) { this.notifications("400","Teléfono, requerido!");return true;}  
        if(this.formulario.dependencia.length==0) { this.notifications("400","Dependencia, requerido!");return true;}  
        if(this.formulario.puesto.length==0) { this.notifications("400","Puesto, requerido!");return true;}  
        if(this.formulario.email.length==0 || this.email_valid.length>0) { this.notifications("400","Correo, requerido!");return true;}
        if(this.formulario.phone.length<8) { this.notifications("400","Teléfono, requerido!");return true;}
        if(this.formulario.username.length<8 || !/[0-9]/.test(this.formulario.username)){ 
          this.notifications("400","Formato usuario, requerido!");return true;}
        if(/[a-zñA-ZÑ]/.test(this.formulario.username)){ 
          if(!/[a-zñ]/.test(this.formulario.username)){this.notifications("400","Formato usuario, requerido!");return true;}}
        if(this.formulario.password.length<8 || !/[a-z]/.test(this.formulario.password) || !/[A-Z]/.test(this.formulario.password)  || !/[0-9]/.test(this.formulario.password)) 
          { this.notifications("400","Formato contraseña, requerido!");return true;}
        if(this.formulario.plataforma==0) { this.notifications("400","Plataforma, requerido!");return true;}
        if(this.plataforma_portal){
          if(this.formulario.config_id==0) { this.notifications("400","Comunidad, requerido!");return true;}
          if(this.formulario.role_id==0) { this.notifications("400","Rol, requerido!");return true;}
        }
        let conjunto=false;
        this.formulario.menu.forEach(dat=> {
          if(typeof(dat.route) != undefined){ if(dat.route=='/reporte-unificado'){ conjunto=true; } }
        });
        if(!this.conjunto){ this.formulario.permisos=[]}

        this.formulario.rfc=this.formulario.rfc.toUpperCase();
        this.formulario.curp=this.formulario.curp.toUpperCase();
        this.formulario.name=this.formulario.name.toUpperCase();
        this.formulario.fathers_surname=this.formulario.fathers_surname.toUpperCase();
        this.formulario.mothers_surname=this.formulario.mothers_surname.toUpperCase();
        this.formulario.puesto=this.formulario.puesto.toUpperCase();
        this.formulario.dependencia=this.formulario.dependencia.toUpperCase();
        return false
      },
      saveUser(){
        if(this.validaciones('insert')){return;}
        if(this.filePdf.length==0){this.notifications("400","Archivo, requerido!"); return false;}
        this.formulario.menu=JSON.stringify(this.data_user_menu);
        //this.formulario.permisos=JSON.stringify(this.formulario.permisos);
        this.formulario.phone=this.formulario.phone.replace(/\D/g, '');
        let formData = new FormData();
        for (let fm in this.formulario) {
          formData.append(fm, this.formulario[fm]);
        }
        let json_user_portal={};
        if(this.plataforma_portal && this.plataforma_admin){
          json_user_portal={ id: this.formulario.user_portal.id, config_id: this.formulario.config_id, role_id: this.formulario.role_id };
        }else{
          json_user_portal={ id: '0', config_id: '0',  role_id: '0' };
        }
        formData.append('user_portal', JSON.stringify(json_user_portal));
        formData.append('permisos', JSON.stringify(this.formulario.permisos));
        formData.append('file_solicitud', this.filePdf);
        formData.append('status', '1');
        axios.post(`${process.env.APP_URL}/modulo-insert-user`, formData, {headers: {'Content-Type': 'multipart/form-data'} })
        .then((response)=>{
          this.notifications(response.data.code,response.data.message);
          if(response.data.code=="200"){
            this.loadTable();
            this.cerrarModal();
          }
        })
        .catch(error=>{
          console.log(error);
            this.carga_archivo=true;              
        });
      },
      updateUser(){
        if(this.validaciones('update')){return;}
        if(this.formulario.phone.length==0) { this.notifications("400","Teléfono, requerido!");return;}   
        this.formulario.phone=this.formulario.phone.replace(/\D/g, '');
        this.formulario.menu=JSON.stringify(this.data_user_menu);
        //this.formulario.permisos=JSON.stringify(this.formulario.permisos);
        let formData = new FormData();
        for (let fm in this.formulario) {
          formData.append(fm, this.formulario[fm]);
        }
        let json_user_portal={};
        if(this.plataforma_portal && this.plataforma_admin){
          json_user_portal={ id: this.formulario.user_portal.id, config_id: this.formulario.config_id, role_id: this.formulario.role_id };
        }else{
          json_user_portal={ id: '0', config_id: '0',  role_id: '0' };
        }
        formData.append('user_portal', JSON.stringify(json_user_portal));
        formData.append('permisos', JSON.stringify(this.formulario.permisos));
        formData.append('status', '1');
        if(this.filePdf.length!=0){ formData.append('file_solicitud', this.filePdf); }
      
        axios.post(`${process.env.APP_URL}/modulo-update-user`,formData, {headers: {'Content-Type': 'multipart/form-data'} })
        .then((response)=>{
          this.notifications(response.data.code,response.data.message);
          if(response.data.code=="200"){
            this.cerrarModal();
            this.loadTable();
          }
        })
        .catch(error=>{
          console.log(error);
            this.carga_archivo=true;              
        });
      },
      catalogos(){
        this.data_roles=[];
        this.data_comunidad=[];
        axios.get(`${process.env.APP_URL}/catalogo-rol-comunidad`)
        .then((response)=>{
          if(response.data.code=="200"){
            this.data_roles=response.data.response.roles;
            this.data_comunidad=response.data.response.comunidad;
          }
        })
        .catch(error=>{
          console.log(error);
            this.carga_archivo=true;
        });
      },
      catalogos_menu(){
        this.items=[];
        this.data_user_menu=[];
        //console.log(this.data_.plataforma);
       
       // console.log(typeof(this.data_.email));
        let email_send=typeof(this.data_.email)=='undefined' ? '0' : this.data_.email
        axios.post(`${process.env.APP_URL}/find-menu-user`,{
          email: email_send
        })
        .then((response)=>{
          //this.items=parseJSON(response.data.admin_menu);
          this.data_user_menu=parseJSON(typeof(response.data.user_menu)=='undefined' ? '[]' : response.data.user_menu );
          this.items=this.parseMenu(parseJSON(response.data.admin_menu));
          if(this.data_.plataforma==0 || this.data_.plataforma=="TRAMITES.NL"){
            this.parseUserMenu([]);
          }else{
            this.parseUserMenu(this.data_user_menu);
          } 
        })
        .catch(error=>{
          console.log(error);
            this.carga_archivo=true;
        });
      },
      parseMenu(d_menu){
        d_menu
        this.data_menu=[];
        let new_data=[];
        d_menu.forEach((men,i_m) => {
          let ch_1=[];
          this.data_menu.push(men.info);
          men.childs.forEach((child_1,i_child_1)=> {
            this.data_menu.push(child_1.info);
            let ch_2=[];
            child_1.childs.forEach((child_2,i_child_2) => {
              this.data_menu.push(child_2);
              ch_2.push({'name':child_2.title,'id':child_2.id});
            });
            ch_1.push({'name':child_1.info.title,'id':child_1.info.id, children: ch_2})
          });
          new_data.push({'name':men.info.title,'id':men.info.id, children: ch_1});
        });
        return new_data;
      },
      parseUserMenu(user_menu){
        this.selected=[];
        user_menu.forEach(um => {
          if(um.route !=="#"){
            this.selected.push(um.id);
          }
        });
      },
      catalogos_permisos(){
        this.data_permisos=[];
        axios.post(`${process.env.APP_URL}/admin-permisos-find`)
        .then((response)=>{
          this.data_permisos=response.data;          
        })
        .catch(error=>{
          console.log(error);
        });
      },
      inputFile(file){
        this.filePdf = file;
      },
      cancelaArchivo(){
        this.filePdf = "";
        $("#delFilePDF").click();
      },
      changePlataforma(){
        this.plataforma_admin=false;
        this.plataforma_portal=false;
        if(this.formulario.plataforma=="TRAMITES.NL"){
          this.plataforma_portal=true;
        }else if(this.formulario.plataforma=="ADMINISTRADOR DE LA TESORERIA"){
          this.plataforma_admin=true;
        }
      },
      accionStatus(dato){
        this.$emit('guardar', dato);
      },
      gPasswordPerf(){
        let pass= Vue.filter('generaPassword')(3,3,2);
        this.formulario.password=pass;
      },
      onechange1(){
        if(this.eyes_change){
          this.eyes_change=false;
        }else{
          this.eyes_change=true;
        }
        
      },
      loadTable(){
        this.$emit('loadTable');
      },
      cerrarModal(){
        $("#delFilePDF").click();
        this.$emit('formulario');
        this.cancelaArchivo();
        this.busqueda='';
        this.btn_quitar=false;
        this.inputDisable=false;
        this.inputNoEmpleado=false;
        this.hidden_agregar=false;
        this.email_valid='';
        this.mensaje_no_empleado='';
        this.formulario={
          id:'',
          plataforma:'0',
          numero_empleado:'',
          name:'',
          fathers_surname:'',
          mothers_surname:'',
          rfc:'',
          curp:'',
          dependencia:'',
          puesto:'',
          phone:'',
          email:'',
          username:'',
          password:'',
          is_admin:'0',
          menu:[],
          config_id:'0',
          permisos:[],
          role_id:'0',
          status:'0',
        }
      },
      notifications(Code,Message){
        if(Code=="200"){  Command: toastr.success(Message, "Notifications");  }
        else{  Command: toastr.warning(Message, "Notifications"); }
      },
      formatoTel(valor){
        if(valor==null){ return;}
        let input = valor.replace(/\D/g, ''); // Eliminar todos los caracteres no numéricos
        // Aplicar formato: (##) ####-####
        input = input.replace(/^(\d{2})(\d)/g, '($1) $2');
        input = input.replace(/(\d{4})(\d)/, '$1-$2');
        return input;
      },
      emailValida(){
        let campo = this.formulario.email;     
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if(campo.length==0){
          this.email_valid = "";
          return;
        }
        if (emailRegex.test(campo)) {
          if (campo.includes('@nuevoleon')){
            this.email_valid = "";
            this. validaEmail(campo);      
          }else{
            this.email_valid = "dominio: @nuevoleon";
          }
        } else {
          this.email_valid = "Formato incorrecto";
        }
      },
      validaEmail(_email){
        this.email_valid='';
        if(this.data_.email==_email){
          return;
        }
        if(_email.length==0){return;}
        axios.post(`${process.env.APP_URL}/valida-email`,{
          email: _email,
        })
        .then((response)=>{ 
          //this.notifications(response.data.code,response.data.message);
          if(response.data.code=="400"){
            this.email_valid=response.data.message;
          }else{
            this.email_valid='';
          }
        })
        .catch(error=>{
            console.log(error);
            this.carga_archivo=true;
            this.email_valid='';             
        });
      },
      rfcValida() {
        this.formulario.rfc=this.formulario.rfc.toUpperCase();
        if(this.formulario.rfc.length<12){this.curp_valid=''; return;}
        let vald=this.rfcValido(this.formulario.rfc,true);
        if (vald) {
         this.rfc_valid = "";
        } else {
          this.rfc_valid = "Formato incorrecto";
        }
      },
      rfcValido(rfc, aceptarGenerico = true) {
        rfc=rfc.toUpperCase();
          const re =/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A-Z\d])$/;
        var validado = rfc.match(re);
        if (!validado)
            return false;
        return true;
      },
      curpValida() {
        this.formulario.curp=this.formulario.curp.toUpperCase();
        if(this.formulario.curp.length<18){ this.curp_valid='';   return false; }
        let curp= this.formulario.curp;
        curp=curp.toUpperCase();
          var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
              validado = curp.match(re);
        
          if (!validado){ this.curp_valid='Formato incorrecto';   return false;  }   
          return true; //Validado
      },
      buscarLi(){
        let search=this.busqueda;
        if (search== "") {
          $(".lista").css("display", "list-item");
        } else {
          $(".lista").each(function() {
            //console.log(search);
            //console.log($(this).text());
            if ($(this).text().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) < 0) {
              $(this).css("display", "none");
            } else {
              $(this).css("display", "list-item");
            }
          });
        }
      },
      formatoTelefono(){
        let input = this.formulario.phone.replace(/\D/g, ''); // Eliminar todos los caracteres no numéricos
        // Aplicar formato: (##) ####-####
        input = input.replace(/^(\d{2})(\d)/g, '($1) $2');
        input = input.replace(/(\d{4})(\d)/, '$1-$2');
        this.formulario.phone= input;
      },
      validaNumeroEmpleado($event){
        if(/[a-zA-Z0-9]/g.test($event.key)){ 
          return true;
        }else{
          $event.preventDefault()
        } 
      },
      validaLetrasNumeros($event){
        if(/[a-zA-Z0-9]/g.test($event.key)){ 
          return true;
        }else{
          $event.preventDefault()
        } 
      },
      validaLetras($event){
        if(/[A-Za-z ÁÉÍÓÚáéíóúÑñ]/g.test($event.key)){ 
          return true;
        }else{
          $event.preventDefault()
        } 
      },
      validaCorreo($event){
        if(/[A-Za-z0-9._@-]/g.test($event.key)){ 
          return true;
        }else{
          $event.preventDefault()
        } 
      },
      validaNumero($event){
        if(/[0-9]/g.test($event.key)){ 
          return true;
        }else{
          $event.preventDefault()
        } 
      }
    },
    watch:{
      data_(dataN){
        //console.log(dataN);
        if(typeof(dataN.id)!==undefined){this.catalogos_menu();}
        this.formulario={
          id:typeof(dataN.id)=='undefined' ? '' : dataN.id,
          plataforma: typeof(dataN.plataforma)=='undefined' ? '0' : dataN.plataforma,
          numero_empleado: typeof(dataN.numero_empleado)=='undefined' ? '' : dataN.numero_empleado,
          name: typeof(dataN.name)=='undefined' ? '' : dataN.name==null ? dataN.nombre_completo : dataN.name,
          fathers_surname: typeof(dataN.fathers_surname)=='undefined' ? '' : dataN.fathers_surname,
          mothers_surname: typeof(dataN.mothers_surname)=='undefined' ? '' : dataN.mothers_surname,
          rfc: typeof(dataN.rfc)=='undefined' ? '' : dataN.rfc,
          curp: typeof(dataN.curp)=='undefined' ? '' : dataN.curp,
          phone: typeof(dataN.phone)=='undefined' ? '' : this.formatoTel(dataN.phone),
          dependencia: typeof(dataN.dependencia)=='undefined' ? '' : dataN.dependencia,
          puesto: typeof(dataN.puesto)=='undefined' ? '' : dataN.puesto,
          email: typeof(dataN.email)=='undefined' ? '' : dataN.email,
          username: typeof(dataN.username)=='undefined' ? '' : dataN.username,
          password:'',
          is_admin: typeof(dataN.is_admin)=='undefined' ? '0' : dataN.is_admin==0 ? '2' : dataN.is_admin,
          menu:[],
          config_id: typeof(dataN.config_id)=='undefined' ? '0' : dataN.config_id,
          permisos: [], ///conjunto de tramites para admin
          role_id: typeof(dataN.role_id)=='undefined' ? '0' : dataN.role_id,
          status: typeof(dataN.status)=='undefined' ? '0' : dataN.status,
          user_portal:{ 
            id: dataN.user_portal == null ? '0' : dataN.user_portal.id,
            config_id: dataN.user_portal == null ? '0' : dataN.user_portal.config_id,
            role_id: dataN.user_portal == null ? '0' : dataN.user_portal.role_id,
            email: dataN.user_portal == null ? '0' : dataN.user_portal.email,
            status: dataN.user_portal == null ? '0' : dataN.user_portal.status
          }
        }
        this.catalogos_permisos();
        if(this.formulario.user_portal.id != '0' ){
          this.btn_quitar=true;
          setTimeout(() => {
            this.plataforma_portal=true;
            this.formulario.config_id= this.formulario.user_portal.config_id;
            this.formulario.role_id= this.formulario.user_portal.role_id;
          }, 300);
        }
        if(typeof(dataN.permiso_relacion)!='undefined'){
          let per=[];
          dataN.permiso_relacion.forEach(p => {
            per.push(p.permiso)
          });
          this.formulario.permisos=per;
        }
        if(typeof(dataN.id)=='undefined'){
          this.inputDisable=false;
        }else{
          this.inputDisable=true;
        }
        if(typeof(dataN.numero_empleado)=='undefined'){
          this.inputNoEmpleado=false;
        }else{
          if(dataN.numero_empleado!=null){
            this.inputNoEmpleado=true;
          }
         
        }
        if(this.formulario.user_portal.id==0){
          this.hidden_agregar=true;
        }else{this.hidden_agregar=false;}
      },

      data_user_menu(menu){
        this.conjunto=false;
        //console.log('menu changes conjunto');
        menu.forEach(dat=> {
          if(typeof(dat.route) != undefined){
            if(dat.route=='/reporte-unificado'){ this.conjunto=true; }
          }
          
        });
        if(!this.conjunto){ this.formulario.permisos}
      },
      selected(selected){
        this.data_user_menu=[];
        selected.forEach(um => {
          let d=[];
          this.data_menu.forEach(e => {
            if(typeof(e['id'])!='undefined'){
              if(e['id'] === um){  d=e; }
            }
          });
          //let d=this.data_menu.filter((e) => e['id'] === um).map((e) => { return e});
          //console.log(d);
          this.data_user_menu.push(d);
        });
        
        //add father
        this.data_user_menu.forEach(dm =>{
          let fat=null;
          this.data_menu.forEach(e => {
            if(typeof(e.id)!= undefined && typeof(dm.id_father)!= undefined){
              if(e.id.toString() == dm.id_father.toString()){  fat=e; }
            }
          });
          //let fat=this.data_menu.filter((e) => e['id'].toString() === dm.id_father.toString()).map((e) => { return e});
          
          if(typeof(fat) == 'object'){
            let vfat=false;
            //console.log(this.data_user_menu);
            this.data_user_menu.forEach(em => {
              //console.log(em);
              if(typeof(em) !== 'undefined' ){ 
                if(em.id.toString() == dm.id_father.toString()){  vfat=true; }
              }
            });
            //let vfat =this.data_user_menu.filter((e) => e['id'].toString() === dm.id_father.toString()).map((e) => { return e});
            if(!vfat){
              this.data_user_menu.push(fat);
            }
          }
        });
      },
    },
    computed: {
    }       
  }
</script>
<style>
.vertical {
  border-left: 1px solid #dddddd;
  height: 280px;
  position:absolute;
}
.valida-letras{
  text-transform: uppercase;
}
.uppercase-text{
  text-transform: uppercase;
}
</style>