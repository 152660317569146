<template>
  <div>
  <portletsample :clase="'container col-md-12'">
    <template slot="actions">
      <a class="btn btn-circle btn-icon-only btn-default fullscreen" href="javascript:;"></a>
    </template>
    <template slot="caption">
      <span class="caption-subject bold uppercase"> <i class="fa fa-bar-chart-o green"></i> Entidades  {{title_c}}</span>
      <span class="help-block">&nbsp;</span>      
    </template>
    <template slot="caption2">
      <div class='col-md-4'> 
        <div class='form-group'>   
         <v-date-picker v-model="range" is-range >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center input-group" >
              <span class='input-group-addon'>De</span>
              <input :value="inputValue.start" v-on="inputEvents.start" class="form-control  rounded border rounded spinner"/>
              <span class='input-group-addon'>A</span>
              <input :value="inputValue.end" v-on="inputEvents.end" class="form-control  rounded border rounded green" />
            </div>
          </template>
        </v-date-picker>
        </div>              
      </div>
      <div class='col-md-3'>
        <div class='form-group'> 
          <select2 class="form-control" :options="options" v-model="id_entidad"  v-on:eventchange="obtenerR()">
            <option value="Todos">Todos</option>
            <option v-for="e in entidades" :value="e.id">{{e.nombre}}</option>
          </select2>
        </div>
      </div>
      <div class='col-md-2'>
        <div class='form-group'> 
          <select2 class="form-control" :options="options" v-model="estatus" v-on:eventchange="obtenerR()">
            <option value="0">Pagado</option>
             <option value="60">Sin Pagar</option>
          </select2>
        </div>
      </div>
      <div class='col-md-2'>
        <div class='form-group'> 
          <select2 class="form-control" :options="options" v-model="title_c" @eventchange="changeTipo">
            <option value="Transacciones">Transacciones</option>
             <option value="Monto Total">Monto Total</option>
          </select2>
        </div>
      </div> 
      <div class="col-md-1"> <button class="btn green " v-on:click="obtenerR()">Buscar</button> </div>
      <div class="col-md-3"> <button class   = "btn btn-default" :disabled="isDisabledp" title="Excel Transacciones por fecha de pago" v-on:click="ReporteIngresos()">Descargar Excel Pagados <span v-bind:class="[{ spinner: isDisabledp}]">&nbsp;&nbsp;&nbsp;&nbsp;</span></button> </div>
      <div class="col-md-3"> <button class   = "btn btn-default" :disabled="isDisabledt" v-on:click="ReporteIngresosTransacciones()"   title="Excel Transacciones por fecha de inicio">Descargar Excel Inicio<span v-bind:class="[{ spinner: isDisabledt}]">&nbsp;&nbsp;&nbsp;&nbsp;</span> </button> </div>
         <div class="col-md-3"> </div>
    </template>
    <template slot="body">
      <line-chart v-if="loaded" :chartdatas="chartdatas"/>
    </template>
  </portletsample>
</div>
</template>

<script>
import axios from 'axios'
import VCalendar from 'v-calendar'
import LineChart from './Chartsjs/BarChart.vue'
import portletsample from './General/Portlet.vue'
import select2 from './General/Select.vue'
export default {
  name: 'ChartRenderBar',
  components: { LineChart,portletsample,VCalendar,select2},
  data(){ 
    return{
      loaded: false,
      estatus:"0",
      title_c:"Transacciones",
      id_entidad:"Todos",
      entidades:[],
      data_p:[],
      data_t:[],
      data_c:[],
      btn_p:false,
      btn_t:false,
      chartdatas: [],
      range: {
        start:this.firtsDate(),
        end: new Date(),       
      },
      options:[],
    name_file:this.filename()
    }
  },
  methods:{
    obtenerEntidad(){
      axios.get(`${process.env.APP_URL}/entidad-find`)
      .then((response)=>{              
        this.entidades=response.data    
      })
    },
    obtenerR(){
      this.loaded = false
      axios.post(`${process.env.APP_URL}/reporte-entidad-c`,{
        incio: new Date(this.range.start).toISOString().slice(0,10),
        fin: new Date(this.range.end).toISOString().slice(0,10),
        status:this.estatus,
        entidad:this.id_entidad})
      .then((response)=>{
        this.loaded = true
        this.data_c=response.data.data_chart            
        //this.data_p=response.data.data_report            
        this.chartdatas=this.findarray()
       
      })       
    },
    ReporteIngresos(){
      this.btn_p=true    
      axios.post(`${process.env.APP_URL}/reporte-ingresos-f`,{
        incio: new Date(this.range.start).toISOString().slice(0,10),
        fin: new Date(this.range.end).toISOString().slice(0,10),
        status:this.estatus,
        entidad:this.id_entidad,
        criteria:"fecha_pago" },{ responseType: 'blob'})
      .then((response)=>{  
         const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download','Reporte-Ingresos-Fecha-Pago-'+ this.name_file);
                document.body.appendChild(link);
                link.click();
        this.btn_p=false 
      }).catch(error=>{
       console.log(error)
       this.btn_p=false
      })
    },
    ReporteIngresosTransacciones(){ 
      this.btn_t=true     
      axios.post(`${process.env.APP_URL}/reporte-ingresos-f`,{
        incio: new Date(this.range.start).toISOString().slice(0,10),
        fin: new Date(this.range.end).toISOString().slice(0,10),
        status:this.estatus,
        entidad:this.id_entidad,
        criteria:"fecha_transaccion"},{ responseType: 'blob'})
      .then((response)=>{          
         const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download','Reporte-Ingresos-Fecha-Inicio-'+ this.name_file);
                document.body.appendChild(link);
                link.click();
        this.btn_t=false
      }).catch(error=>{
       console.log(error)
       this.btn_t=false
      })
    },
    changeTipo(event) {
      this.loaded = false
      this.chartdatas=this.findarray()
      let selft=this
      setTimeout(function() {selft.loaded=true}, 100)
      //this.loaded = true      
    },
    firtsDate(){
      var date = new Date();
      var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
      return primerDia
    },
    findarray(){

     var data= this.data_c
     var d=[]
     
      if(this.title_c=="Transacciones")
      { 
        data.forEach((item,ind)=>{
          Object.assign(data[ind],{"data": item.data_transacciones})
        })
         d=Object.assign(data,{format:"0"})
        
      }else{
        data.forEach((item,ind)=>{
          Object.assign(data[ind],{"data": item.data_total})
        })
        d=Object.assign(data,{format:"1"})
      }
      
      return d
    },
    filename(){
      var date =  new Date()
     return ""+date.getFullYear()+"-"+(date.getMonth() + 1) +"-"+date.getDate()+"-"+date.getHours()+""+date.getMinutes()+""+date.getSeconds()+".xlsx"
    }
  },
  computed: {
    isDisabledp: function(){
      return this.btn_p;
    },
    isDisabledt: function(){
      return this.btn_t;
    }
  },
  mounted() {    
    try {
      this.obtenerEntidad()
      this.obtenerR()    
     
    } catch (e) {
      console.error(e)
    }
  }

}
</script>