<template>
    <div >
      <portlet :clase="'container col-md-12'">
        <template slot="actions"> 
          <a class="btn btn-circle btn-icon-only btn-success" tarjet="_blank" :href="link_download" data-toggle='modal' title='Descargar Reporte' ><i class="fa fa-file-excel-o"></i></a>
        </template>
        <template slot="caption">
          <div class="row"> 
            <div class='col-md-6'>          
              <span class="caption-subject bold uppercase"><i class="fa fa-search"></i> Filtros</span>          
            </div> 
          </div>  
        </template>
        <template slot="caption2">
          <div class="row">	
            <div class='col-md-4 col-ms-12'>
                <div class='form-group'>   
                    <label for='fecha'>Seleccionar Rango de Fechas. </label>
                    <v-date-picker v-model="range" is-range >
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="flex justify-center items-center input-group" >
                            <span class='input-group-addon'>De</span>
                            <input :value="inputValue.start" v-on="inputEvents.start" class="form-control  rounded border rounded"/>
                            <span class='input-group-addon'>A</span>
                            <input :value="inputValue.end" v-on="inputEvents.end" class="form-control  rounded border rounded green" />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
            </div>
            <div class="col-md-1 col-ms-12">
                <label>&nbsp;</label>
                <button type="button" class="btn green enableBtn" v-on:click="filterResponse()" >Buscar</button>
            </div>            
          </div> 
        </template>
      </portlet>
      <portlet :clase="'container col-md-12 blockui_sample_3_2_element'">
        <template slot="actions"></template>
        <template slot="caption">
          <div class="row"> 
            <div class='col-md-6'>          
              <span class="caption-subject bold uppercase"> <i class="fa fa-cogs"></i> Registros</span>          
            </div> 
          </div>  
        </template>      
        <template slot="body">         
          <div class="row">
            <tableSample :lengthMenu="lengthMenu" @guardar="accionDetalle" :data_="data_" :id="'sample_2'" v-if="renderTable"/>         
          </div>
        </template>
      </portlet>
      <modal :id="data_id"></modal>
    </div>
  </template>  
  <script>
  import axios from 'axios'
  import portlet from './General/Portlet.vue'
  //import modal from './General/Modal.vue'
  import modal from './Individual/RecModal.vue'
  import modalconfirmation from './General/ModalConfirmation.vue'
  import tableSample from './Individual/RecTable.vue'
  import VCalendar from 'v-calendar'
  export default {
    name: 'ReporteEstadistica',
    components: {axios,portlet,modal,modalconfirmation,tableSample,VCalendar},
    data(){return{
        folio:'',
        data_:[],
        range: {
            start:'',
            end: '',       
        },
        renderTable:false,
        lengthMenu:[],
        data_id:[],
        link_download:''
      }
    },
    methods:{
      accionDetalle(id){
        this.data_id= id;
      },
      filterResponse(){
          this.loagingC(true);
          this.data_=[];
          this.renderTable=false;
          axios.post(`${process.env.APP_URL}/rec-consulta`,{
            inicio: new Date(this.range.start).toISOString().slice(0,10),
            fin: new Date(this.range.end).toISOString().slice(0,10)})
          .then((response)=>{         
            //console.log(response.data); 
            this.data_=response.data;
            this.renderTable=true;
            this.loagingC(false);
          }).catch(error=>{
            this.notifications('400',"Registros no encontrados");
            this.loagingC(false);
            console.log(error)
          })
      },
      changedate(date_){
        console.log(date_);
      },
      loagingC(value, index, values) {
        return Vue.filter('loadingCharge')(value);
      },
      firtsDate(){
        var date = new Date();
        var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
        return primerDia
      },
    },
    mounted() {
      ComponentsPickers.init();
    },
    watch:{
      range(range){
        if(this.range.start.length==0 || this.range.end.length==0){
          this.link_download='';
        }else{
          let inicio=new Date(this.range.start).toISOString().slice(0,10);
          let fin= new Date(this.range.end).toISOString().slice(0,10);
          this.link_download=`${process.env.APP_URL}/rec-report?inicio=` + inicio +'&fin=' + fin;
        }
      }
    }
  }
  </script>