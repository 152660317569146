<template>
    <div>
      <div class="table-responsive">
        <table class="table table-hover" v-bind:id="'sample_2'"  width="100%">
          <thead>
              <tr>
                  <th>Folio ID</th>
                  <th>Licencia</th>                  
                  <th>RFC</th>
                  <th>Estatus</th>
                  <th>Vigencia del Cobro</th>                  
                  <th>Usuario</th>
                  <th>Estatus Bitacora</th>
                  <th>Fecha Registro</th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
            <!---  <tr v-for="dato in data_">
                  <td>{{dato.folio_id}}</td>
                  <td>{{dato.licencia}}</td>
                  <td>{{dato.rfc}}</td>
                  <td><span v-bind:class="[dato.estatus=='VIGENTE' ? 'btn-success': dato.estatus==='REFERENCIA ACTIVA' ? 'btn-warning' : dato.estatus=='PAGADO' ? 'label-default' : 'btn-danger']" class="label  btn-circle">{{dato.estatus}} </span> </td>
                  <td>{{dato.vigencia_del_cobro}}</td>
                  <td>{{dato.usuario}}</td>
                  <td><span v-bind:class="[dato.estatus_bitacora=='3' ? 'btn-info' : dato.estatus_bitacora=='2' ? 'btn-warning' : 'btn-primary']" class="label  btn-circle"> 
                  {{  dato.estatus_descripcion }}</span> </td>
                  <td>{{dato.created_at}}</td>
                  <td><a class="btn btn-icon-only btn-circle green" data-toggle="modal" href="#portlet-edit" title="Editar Registro"  @click="detalleRegistros(dato)"><i class="fa fa-bars"></i></a></td>
              </tr> -->
          </tbody>             
        </table>
      </div>
    </div>
  </template>
  <script>
    export default {
      name:"TableSample",
      props: {id:'',data_:[],lengthMenu:[]},
      mounted () {
        //TableManaged2.init2();
        this.createTable();
        window.tableBitacora = this;
      },      
      methods:{
        detalleRegistros(dato){
          this.$emit('guardar', dato);
        },
        estatusBitacora(e){
            let r='';
            if(e=="2"){
                r='Incidencia';
            }else if(e=="3"){
                r='Nuevo Registro';
            }else{
                r='Actualización';
            }
            return r;
        },
        createTable(){
          $('#sample_2').DataTable( {
            "data": this.data_,
              "columns": [
                {"data": "folio_id"},
                {"data": "licencia"},
                {"data": "rfc"},
                {"render": this.spanEstatus },
                {"data":"vigencia_del_cobro"},
                {"data":"usuario"},
                {"render": this.spanEstatusBitacora }, 
                {"data":"created_at"},       
                {"render": this.btnDetalle },           
              ]
          });
        },
        btnDetalle( data, type, row, meta){
          return "<a class='btn btn-icon-only btn-circle green' data-toggle='modal' href='#portlet-edit' title='Editar Registro'  onclick='detalleRegistros("+JSON.stringify(row)+")'><i class='fa fa-edit'></i></a>"
        },
        spanEstatus( data, type, row, meta){
          let status=row.estatus==='VIGENTE' ? 'btn-success' : row.estatus==='REFERENCIA ACTIVA' ? 'btn-warning' : row.estatus==='PAGADO' ? 'label-default' : 'label-danger'
          return '<span class="label  btn-circle '+status+'" class="">'+row.estatus+' </span>';
        },
        spanEstatusBitacora( data, type, row, meta){
          let status=row.estatus_bitacora=='3' ? 'btn-info' : row.estatus_bitacora=='2' ? 'btn-warning' : 'btn-primary';
          return '<span class="label  btn-circle '+status+'" class="">'+row.estatus_descripcion+' </span>';
        }
      }        
    }
  </script>