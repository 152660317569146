<template>
    <div>
      <modal :id="'modal-conjunto'" :modal="'modal-lg'">      
        <template slot="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"  v-on:click="clearInput()"></button>
            <h4 class="modal-title"><i class="fa fa-cog"></i> Conjunto de trámites</h4>
        </template>
        <template slot="modal-body" >           
            <div class="row"><span class="help-block">&nbsp;</span>
                <div class="col-md-12">
                    <div class="col-md-5 col-ms-12">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="new_permiso" name="new_permiso" autocomplete="off" placeholder="Nuevo Conjunto...">
                        </div>
                    </div>
                    <div class="col-md-1 col-ms-12">
                        <div class="form-group">
                            <button type="button" class="btn green enableBtn" id="btn_save" v-on:click="savePermisos()">Agregar</button>
                        </div>
                    </div>
                    <div class="col-md-12" >
                        <div class="col-md-4 col-ms-12">
                            <div class="form-group">
                                <label >Conjuntos Registrados (selecciona)</label>
                            </div>                            
                        </div>
                        <div class="col-md-5 col-ms-12">
                            <select2 class="form-control" :options="options" v-model="conjunto"  @eventchange="configuradoPermisos()"> 
                                <option  :value="'0'"> --- Selecciona ---</option>
                                <option v-for="b in data_conjunto" :value="b.id">{{b.nombre}}</option>
                            </select2>
                        </div>
                    </div>
                    <div class="col-md-12" >
                        <div class="panel-group accordion scrollable" id="accordion2">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion2" href="#collapse_2_2" aria-expanded="true">Menú </a>
                                    </h4>
                                </div>
                                <div id="collapse_2_2" class="panel-collapse in">
                                    <div class="panel-body" style="height:350px;">
                                        <div class="col-md-6" style="height:330px; overflow-y:auto;overflow-x:auto;">
                                        <h4>Listado de trámites:</h4>
                                        <v-treeview v-model="selected" :items="items" selectable  return-object open-all></v-treeview>
                                        </div>
                                        <div class="col-md-6" style="height:330px; overflow-y:auto;">
                                            <div class="form-group" style="padding-left:16px;">
                                                <h4>Trámites agregados:</h4>
                                                <input type="text" class="form-control" placeholder="Busqueda" v-model="busqueda_conjunto" v-on:keyup="buscarLista()">
                                                <span class="help-block">&nbsp;</span>
                                                <ul style="padding-left:16px;">
                                                    <li class="list-tramites" v-for="tm in selected"> {{ tm.name}} </li>
                                                </ul>
                                            </div>
                                        </div>
                                         <div class="col-md-12"><span class="help-block">&nbsp;</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template slot="modal-footer">
          <button type="button" class="btn green" v-on:click="saveConjuntoTramite()">Guardar conjunto de trámites</button>
          <button type="button" data-dismiss="modal" class="btn default" v-on:click="clearInput()">Cerrar</button>
        </template>
      </modal>
    </div>
</template>
<script>
  
    import axios from 'axios'
    import select2 from '../General/Select.vue'
    import modal from '../General/Modal.vue'
    export default {
        data(){
            return{
            inputDisable:false,
            items: [],
            selected:[],
            busqueda_conjunto:'',
            data_comunidad: [],
            data_permisos: [],
            data_conjunto: [],
            conjunto: '0',
            options:[],
            new_permiso:''
            }
        },
        components: {select2,modal},
        name:"ModalConjunto",
        props: {},
        mounted () {
            this.catalogosPermisos();
            this.catalogosComunidades();
        },      
        methods:{
            saveConjuntoTramite(){
                let ata_ =this.selected;
                if(this.conjunto=='0'){
                    this.notifications('400','Selecciona el conjunto, requerido!');
                    return;
                }
                axios.post(`${process.env.APP_URL}/save-conjuntos-tramites` + '/'+this.conjunto,{
                    conjuntos:ata_
                })
                .then((response)=>{
                    this.notifications(response.data.Code,response.data.Message);
                })
                .catch(error=>{
                    console.log(error);
                });
            },
            savePermisos(){
                if(this.new_permiso.length==0){
                    this.notifications('400','Nuevo conjunto, requerido!');
                    return;}
                axios.post(`${process.env.APP_URL}/admin-permisos-create`,{
                    nombre:this.new_permiso
                })
                .then((response)=>{
                    this.notifications(response.data.Code,response.data.Message);
                    if(response.data.Code=="200"){
                        this.new_permiso="";
                        this.catalogosPermisos();
                    }
                })
                .catch(error=>{
                    console.log(error);
                });
            },
            configuradoPermisos(){
                this.selected=[];
                if(this.conjunto=='0'){return;}
                
                axios.get(`${process.env.APP_URL}/find-conjuntos-tramites` + '/'+this.conjunto)
                .then((response)=>{
                    this.selected=response.data;
                })
                .catch(error=>{
                    console.log(error);
                });
            },
            catalogosPermisos(){
                this.data_conjunto=[];
                axios.post(`${process.env.APP_URL}/admin-permisos-find`)
                .then((response)=>{
                    this.data_conjunto=response.data;
                })
                .catch(error=>{
                console.log(error);
                });
            },
            catalogosComunidades(){
                this.items=[];
                axios.get(`${process.env.APP_URL}/find-comunidades-tramites`)
                .then((response)=>{
                    this.items=response.data;
                })
                .catch(error=>{
                console.log(error);
                });
            },
            notifications(Code,Message){
                if(Code=="200"){  Command: toastr.success(Message, "Notifications");  }
                else{  Command: toastr.warning(Message, "Notifications"); }
            },
            buscarLista(){
                let search=this.busqueda_conjunto;
                if (search== "") {
                    $("ul .list-tramites").css("display", "block");
                } else {
                $("ul .list-tramites").each(function() {
                    //console.log(search);
                    //console.log($(this).text());
                    if ($(this).text().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) < 0) {
                        $(this).css("display", "none");
                    } else {
                        $(this).css("display", "block");
                    }
                });
                }
            },
            clearInput(){
                this.conjunto= '0';
            }
        },
        watch:{

        },
        computed: {

        }       
    }
</script>
<style>

</style>