<template>
    <div>
      <table class="table table-hover" v-bind:id="id"  width="100%">
        <thead>
          <tr>
            <th>Trámite</th>
            <th>Situación</th>
            <th>Estatus</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="b in data_">
            <td>{{ b.desc}}</td>
            <td>
              <span v-bind:class="[b.detalle==null ? 'mod-default' : 'mod-success']" class="label  btn-circle"> 
              {{  b.detalle==null ? 'Sin recibo': 'Con recibo'  }}</span></td>
            <td>
              <span v-bind:class="[b.detalle=='' && b.preview!=null ? 'mod-warning' : b.detalle!=null ? 'mod-success' : 'mod-warning']" class="label  btn-circle">{{ b.preview!=null && b.detalle=='' ? 'Cambios Pendiente' : b.detalle!=null ? 'Publicado' : 'Sin configurar' }} </span>
            </td>
            <td >
              <a class="btn btn-icon-only btn-circle green" data-toggle="modal" href="#modal-recibo" title="Editar Registro"  @click="acciontabla(b)"><i class="fa fa-edit"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  <script>
    export default {
      name:"TableSampleRecibo",
      props: {id:'',data_:[],lengthMenu:[]},
      mounted () {
        //$('#'+this.id).DataTable();
        TableManaged2.init2();
      },      
      methods:{
        acciontabla(dato){
          this.$emit('guardar', dato);
        }
      }        
    }
  </script>
  <style>
    .mod-success{
      background-color:#91d34d !important;
      border-color:#91d34d !important;
      color:rgb(255, 255, 255);
    }
    .mod-warning{
      background-color:#f4d037 !important;
      border-color:#f4d037 !important;
      color:rgb(255, 255, 255);
    }
    .mod-default{
      background-color:#bcbcbc !important;
      border-color:#bcbcbc !important;
      color:rgb(255, 255, 255);
    }
  </style>