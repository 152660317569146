<template>
    <div >
      <portlet :clase="'container col-md-12 blockui_sample_3_2_element'" > 
        <template slot="actions"> 
          <a class="btn btn-circle btn-icon-only btn-success" :href="link_download" data-toggle='modal' title='Descargar Excel' ><i class="fa fa-file-excel-o"></i></a>
          <a class="btn btn-circle btn-icon-only btn-default" href="#portlet-upload-doc" data-toggle='modal' title='Carga base de datos' v-on:click="verificaCarga()"><i class="fa fa-cloud-upload"></i></a>          
        </template>
        <template slot="caption">
          <div class="row"> 
            <div class='col-md-6'>          
                <span class="caption-subject bold uppercase"> 
                    <i class="fa fa-database"></i> Cargar registros 
                    &nbsp;<a class="btn btn-circle btn-icon-only btn-default" title='Recargar registros' v-on:click="loadTableSample()"><i class="fa fa-rotate-left"></i></a>
                </span>         
            </div>
           <h5><div class='col-md-1 text-center'><span class="label btn-warning btn-circle">&nbsp;&nbsp;</span>REFERENCIA ACTIVA</div>
           <div class='col-md-1  text-center'><span class="label label-default btn-circle">&nbsp;&nbsp;</span>PAGADO</div>
          <div class='col-md-1 text-center'><span class="label btn-success btn-circle">&nbsp;&nbsp;</span>VIGENTE</div>
          <div class='col-md-1 text-center'><span class="label btn-danger btn-circle">&nbsp;&nbsp;</span>ADEUDO</div> </h5>           
          </div>  
        </template>      
        <template slot="body">         
          <div class="row">           
            <tableSample :lengthMenu="lengthMenu" @guardar="editarRegistro" :data_="data_" :id="'sample_2'" v-if="renderTable"/>  
          </div>
        </template>
      </portlet>
      <modalconfirmation :id="'portlet-upload-doc'" >      
        <template slot="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title"><i class="fa fa-cloud-upload"></i> Cargar base de datos</h4>
        </template>
        <template slot="modal-body">
            <div v-if="carga_archivo">
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-warning alert-dismissable"><strong>La última carga finalizó: </strong> {{ fecha_carga }}
                        </div>
                    </div>
                </div> 
                <span class="help-block">&nbsp;</span>        
                <div class="fileinput fileinput-new" data-provides="fileinput">                       
                    <span class="btn green btn-file">
                    <span class="fileinput-new">
                    <i class="fa fa-plus"></i>&nbsp; &nbsp;Adjuntar Archivo </span>
                    <span class="fileinput-exists">
                    <i class="fa fa-exchange"></i>&nbsp; &nbsp;Cambiar Archivo </span>
                    <input type="file" name="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="file" v-on:change="handleFileUpload( $event )">
                    </span>
                    <button type="submit" class="btn blue fileinput-exists enableBtn" v-on:click="saveFile()"><i class="fa fa-cloud-upload"></i> &nbsp;Guardar</button>
                    <span class="fileinput-filename" style="text-overflow: ellipsis;width: 240px;overflow: hidden; white-space: nowrap;">
                    </span>&nbsp; <a href="javascript:;" class="close fileinput-exists" data-dismiss="fileinput" id="delFile">
                    </a>               
                </div>
            </div>
            <div v-if="!carga_archivo">
                <div class="row">
                    <span class="help-block">&nbsp;</span>
                    <div class="col-md-12">
                        <div class="alert alert-warning alert-dismissable">
                            <center> <h4>El archivo está siendo procesado. </h4> </center>
                        </div>
                    </div>
                    <span class="help-block">&nbsp;</span>
                </div>
            </div>
            
        </template>
        <template slot="modal-footer">
          <button type="button" data-dismiss="modal" class="btn default" v-on:click="closeClear()">Cerrar</button>
        </template>
      </modalconfirmation>
      <modal :id="'portlet-edit'" :modal="'modal-lg'">      
        <template slot="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" v-on:click="closeClear()"></button>
            <h4 class="modal-title"><i class="fa fa-cogs"></i> Administración de base de datos</h4>
        </template>
        <template slot="modal-body">
            <div class="row">
                <portlet :clase="'container col-md-12'" > 
                    <template slot="tools">
                        <a href="javascript:;" class="collapse"></a>
                    </template>
                    <template slot="caption">
                        <h4><strong>Detalles</strong></h4>
                    </template>
                    <template slot="body" :style="'display: none;'">
                        <div class="row">
                            <div id="addDetalles" v-for="( d  , index ) in detalle_">
                                <div v-if="index == 'folio_id' || index == 'licencia' || index == 'municipio' || index == 'derecho_en_pesos' || index == 'sancion_en_pesos' || index == 'actualizacion' || index == 'recargos' || index == 'total'" class='col-md-6'>
                                    <div class='form-group'>
                                        <label><strong>{{index.replaceAll("_", " ").toUpperCase()}}</strong></label>
                                        <br><label>{{d}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </template>
                </portlet> 
            </div>         
            <div class="row">
                <portlet :clase="'container col-md-12 blockui_sample_3_2_element'" > 
                    <template slot="tools">
                        <a href="" class="collapse" data-original-title="" title=""></a>
                    </template>
                    <template slot="caption">
                        <h4><strong>Actualizar registro</strong></h4>
                    </template>
                    <template slot="body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="titular">TITULAR</label>
                                    <input type="text" class="form-control" name="titular" v-model="titular"  placeholder="TITULAR" :disabled="inputDisable==true">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="domicilio">DOMICILIO</label>
                                    <input type="text" class="form-control" name="domicilio" v-model="domicilio"  placeholder="DOMICILIO" :disabled="inputDisable==true">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="nombre_comercial">NOMBRE COMERCIAL</label>
                                    <input type="text" class="form-control" name="nombre_comercial" v-model="nombre_comercial"  placeholder="NOMBRE COMERCIAL" :disabled="inputDisable==true">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="rfc">RFC</label>
                                    <input type="text" class="form-control" name="rfc" v-model="rfc"  placeholder="RFC" :disabled="inputDisable==true">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="decripcion_del_giro">DESCRIPCIÓN DEL GIRO</label>
                                    <input type="text" class="form-control" name="decripcion_del_giro" v-model="descripcion_del_giro"  placeholder="DESCRIPCIÓN DEL GIRO" :disabled="inputDisable==true">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="ubicacion">UBICACIÓN</label>
                                    <input type="text" class="form-control" name="ubicacion" v-model="ubicacion"  placeholder="UBICACIÓN" :disabled="inputDisable==true">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="vigencia_del_cobro">VIGENCIA DEL COBRO</label>
                                    <v-date-picker v-model="vigencia_del_cobro" >
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div class="flex justify-center items-center input-group" :style="styleVigencia">
                                            <input :value="inputValue" v-on="inputEvents" class="form-control  rounded border rounded" :disabled="inputDisableVigencia==true"/>
                                            </div>
                                        </template>
                                    </v-date-picker>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="estatus">ESTATUS</label>
                                    <select2 class="form-control" :options="options" v-model="estatus" :disabled="inputDisable==true" @eventchange="onchageStatus">
                                        <option v-for="b in data_estatus" :value="b.value">{{b.name}}</option>
                                    </select2>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="tipo_de_pago">TIPO DE PAGO</label>
                                    <select2 class="form-control" :options="options" v-model="tipo_de_pago" :disabled="inputDisable==true">
                                        <option v-for="b in data_tipo" :value="b.value">{{b.name}}</option>
                                    </select2>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="folio_de_pago">FOLIO DE PAGO</label>
                                    <input type="text" class="form-control" name="folio_de_pago" v-model="folio_de_pago" placeholder="FOLIO DE PAGO" :disabled="inputDisable==true">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                   <button class="btn btn-green" v-on:click="updateRegistro()" :disabled="inputDisable==true"><i class="fa fa-check"></i> Actualizar</button>
                                </div>
                            </div>
                        </div>                            
                    </template>
                </portlet>
            </div>
        </template>
        <template slot="modal-footer">
          <button type="button" data-dismiss="modal" class="btn default" v-on:click="closeClear()">Cerrar</button>
        </template>
      </modal>
    </div>
  </template>  
  <script>
  import axios from 'axios'
  import select2 from './General/Select.vue'
  import portlet from './General/Portlet.vue'
  import modal from './General/Modal.vue'
  import modalconfirmation from './General/ModalConfirmation.vue'
  import tableSample from './General/TableSample.vue'
  import VCalendar from 'v-calendar'
  export default {
    name: 'ReporteEstadistica',
    components: {axios,select2,portlet,modal,modalconfirmation,tableSample,VCalendar},
    data(){
        return{
            lengthMenu:[[10, 25, 50, -1],[10, 25, 50, 'All']],
            file:'',
            data_:[],
            options: [],
            render:'',
            dato:[],
            detalle_:[],
            estatus:'',
            options:[],
            data_estatus:[
                {"value":"ADEUDO","name":"ADEUDO"},
                {"value":"VIGENTE","name":"VIGENTE"},
                {"value":"PAGADO","name":"PAGADO"},
                {"value":"REFERENCIA ACTIVA","name":"REFERENCIA ACTIVA"}
            ],
            data_tipo:[
                {"value":"","name":"----selecciona----"},
                {"value":"EN LÍNEA","name":"EN LÍNEA"},
                {"value":"PRESENCIAL","name":"PRESENCIAL"},
                {"value":"TRAMITES NL","name":"TRAMITES NL"}
            ],
            id_registro:'',
            titular:'',
            domicilio:'',
            nombre_comercial:'',
            rfc:'',
            descripcion_del_giro:'',
            ubicacion:'',            
            folio_de_pago:'',
            tipo_de_pago:'',   
            vigencia_del_cobro:'',
            vigencia_del_cobro_old:'',
            renderTable:false,
            inputDisable:false,
            inputDisableVigencia:false,
            styleVigencia:"",
            link_download:`${process.env.APP_URL}/download-alcoholes` ,
            carga_archivo:false,
            fecha_carga:''
        }
    },
    methods:{
        handleFileUpload(event){
            this.file = event.target.files[0];
        },
        verificaCarga(){
            this.carga_archivo=false;
            this.fecha_carga='';             
            axios.get(`${process.env.APP_URL}/verifica-alcoholes`)
                .then((response)=>{
                    let data_a=response.data.response;
                    if(response.data.status=="200"){
                        if(response.data.response[0]["status"]==0){
                            this.carga_archivo=false;
                            this.fecha_carga='';
                        }else{
                            this.carga_archivo=true;
                            this.fecha_carga=response.data.response[0]["fecha"];
                        }                        
                    }else{
                        this.carga_archivo=false;
                        this.fecha_carga='';
                    }
                })
                .catch(error=>{
                    console.log(error);
                    this.carga_archivo=true;              
                });
        },
        loadTableSample(){
            this.renderTable = false 
            this.loagingC(true);
            this.data_=[];                  
            axios.get(`${process.env.APP_URL}/padron-alcoholes`)
                .then((response)=>{
                    this.data_=response.data.response;
                    this.renderTable = true
                    this.loagingC(false);
                })
                .catch(error=>{
                    console.log(error);                    
                    this.loagingC(false);                    
                });
        },
        saveFile(){
            //this.renderTable = false
            if(this.file.length==0){this.notifications("400","Archivo, requerido!"); return false;}
            let formData = new FormData();
            formData.append('fileExcel', this.file);
            axios.post(`${process.env.APP_URL}/import-alcoholes`,
                formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response)=>{
                    //this.renderTable = true
                    this.notifications(response.data.status,response.data.message);
                    this.closeClear();
                    this.carga_archivo=false;
                    this.fecha_carga='';
                })
                .catch(error=>{
                    console.log(error);
                }
            );
        },
        updateRegistro(){
            if( this.inputDisable){
                return;
            }
            if(this.estatus=="PAGADO"){
                if(this.tipo_de_pago.length==0){
                    this.notifications("400","Tipo de Pago, Requerido!");
                    return;
                }
                if(this.folio_de_pago.length==0){
                    this.notifications("400","Folio Pago, Requerido!");
                    return;
                }                
            }
            if(this.estatus!="PAGADO"){
                if(this.tipo_de_pago.length>0){
                    this.notifications("400","Tipo de Pago, No requerido!");
                    return;
                }
                if(this.folio_de_pago.length>0){
                    this.notifications("400","Folio Pago, No requerido!");
                    return;
                }                
            }
            if(this.tipo_de_pago=="TRAMITES NL"){
                this.notifications("400","Tipo de Pago,No Valido!");
                return;
            }
            let date=this.vigencia_del_cobro;
           // console.log(this.vigencia_del_cobro);
            const year=date.getFullYear();
            const month=date.getMonth() + 1;
            const day=date.getDate();
            let fecha=year + "-" + month.toString().padStart(2, '0') +"-"+day.toString().padStart(2, '0');
            axios.post(`${process.env.APP_URL}/update-alcoholes`,{
                id:this.id_registro,
                titular:this.titular,
                domicilio:this.domicilio,
                nombre_comercial:this.nombre_comercial,
                rfc:this.rfc,
                descripcion_del_giro:this.descripcion_del_giro,
                ubicacion:this.ubicacion,
                tipo_de_pago:this.tipo_de_pago,
                folio_de_pago:this.folio_de_pago,
                estatus:this.estatus,
                vigencia_del_cobro:fecha
                })
                .then((response)=>{
                    //this.renderTable = true
                    this.notifications(response.data.status,response.data.message);
                    if(response.data.status=="200"){
                        this.loadTableSample();
                    }
                })
                .catch(error=>{
                    console.log(error);
                }
            );
        },
        downloadFile(){           
            axios.get(`${process.env.APP_URL}/download-alcoholes`)
                .then((response)=>{           
            
                })
                .catch(error=>{
                    this.notifications("400","Error al descargar");
                    console.log(error);
                }
            );
        },
        editarRegistro(dato){
            if(dato.estatus == 'PAGADO' || dato.estatus =='REFERENCIA ACTIVA'){
                this.addTipoPago(true);
            }else{this.addTipoPago(false);}
            this.vigencia_del_cobro="";
            this.detalle_=dato;
            this.id_registro=dato.id;
            this.titular=dato.titular == null ? '' : dato.titular;
            this.domicilio=dato.domicilio == null ? '' : dato.domicilio;
            this.nombre_comercial=dato.nombre_comercial == null ? '' : dato.nombre_comercial;
            this.rfc=dato.rfc == null ? '' : dato.rfc;
            this.descripcion_del_giro=dato.descripcion_del_giro == null ? '' : dato.descripcion_del_giro;
            this.ubicacion=dato.ubicacion == null ? '' : dato.ubicacion;            
            //this.folio_de_pago=dato.folio_de_pago == null ? '' : dato.folio_de_pago;
            setTimeout(() => {
                this.tipo_de_pago=dato.tipo_de_pago == null ? '' : dato.tipo_de_pago; 
                this.estatus=dato.estatus == null ? '' : dato.estatus;
                this.folio_de_pago= dato.estatus=="REFERENCIA ACTIVA" ? '' : dato.folio_de_pago == null ? '' : dato.folio_de_pago;
            }, 200);
           
            let date="";
            let fecha = new Date(dato.vigencia_del_cobro == null ? '' : dato.vigencia_del_cobro);
            fecha=fecha.setDate(fecha.getDate() + 1);
            date= new Date(fecha);
            this.vigencia_del_cobro=date;
            this.vigencia_del_cobro_old=date;
            this.$forceUpdate();
            if(dato.estatus=="PAGADO" || dato.estatus=="REFERENCIA ACTIVA" ){
                this.inputDisable=true;
                this.inputDisableVigencia=true;
                this.styleVigencia="pointer-events: none;";
            }else{
                this.inputDisable=false;
                this.styleVigencia="";
                this.inputDisableVigencia=false;
                if(dato.estatus=="ADEUDO" ){
                    this.styleVigencia="pointer-events: none;";
                    this.inputDisableVigencia=true;
                }
            }
        },
        addTipoPago(tipo){
            console.log(typeof(this.data_estatus[3]));
            if(tipo){
                if(typeof(this.data_estatus[3])=='undefined'){
                    this.data_estatus.push( {"value":"REFERENCIA ACTIVA","name":"REFERENCIA ACTIVA"});
                    this.data_tipo.push({"value":"TRAMITES NL","name":"TRAMITES NL"});
                }
            }else{
                if(typeof(this.data_estatus[3])!=='undefined'){
                    this.data_estatus.splice(3, 1);
                    this.data_tipo.splice(3, 1);
                }
            }
        },
        onchageStatus(){
            if(this.estatus=="VIGENTE"){
                this.styleVigencia="";
                this.inputDisableVigencia=false;
            }else{
                this.vigencia_del_cobro=this.vigencia_del_cobro_old;
                this.styleVigencia="pointer-events: none;";
                this.inputDisableVigencia=true;
            }
        },
        cerrarModal(){
            this.id_registro='';
            this.titular='';
            this.domicilio='';
            this.nombre_comercial='';
            this.rfc='';
            this.descripcion_del_giro='';
            this.ubicacion='';
            this.tipo_de_pago='';
            this.folio_de_pago='';
            this.estatus='';
            this.vigencia_del_cobro='';
            this.vigencia_del_cobro_old='';
            //$('#vigencia_del_cobro').datepicker('setDate', '')
        },
        closeClear(){
            //document.getElementById('delFile').click();
            $("#delFile").click();
        },
        fmoneda(value, tipo, values) {
            return Vue.filter('chartTick')(value,tipo);
        },
        loagingC(value, index, values) {
            return Vue.filter('loadingCharge')(value);
        },
        notifications(Code,Message){
            if(Code=="200"){
                Command: toastr.success(Message, "Notifications");
            }else{
                Command: toastr.warning(Message, "Notifications");
            }
        }
    },
    mounted() {
        this.loadTableSample();
    } 
  }
  </script>