<template>
    <div>
        <portlet :clase="'container col-md-12 blockui_sample_3_2_element'">
            <template slot="actions"> </template>
            <template slot="caption">
                <div class="row"> 
                <div class='col-md-6'>          
                    <span class="caption-subject bold uppercase"><i class="fa fa-cogs"></i> Configuración</span>          
                </div> 
                </div>  
            </template>
            <template slot="body">
                <div class="row">
                    <div class="col-md-12">
                        <tableSample :lengthMenu="lengthMenu" @guardar="accionRecibo" :data_="servicios" :id="'sample_2'" v-if="renderTable"/>
                    </div>
                </div> 
            </template>
        </portlet>
        <modal :id="'modal-recibo'" :modal="'modal-lg'">      
            <template slot="modal-header">
                <button type="button" class="close" data-dismiss="modal"aria-hidden="true"></button>
                <h4 class="modal-title"><i class="fa fa-file-code-o"></i> Recibo - <label style="color: #cb5a5e;">{{ nombre_tramite }}</label></h4>
            </template>
            <template slot="modal-body">
                <div class="alert alert-warning alert-dismissable" v-if="hidden_alert">
                    <strong>NOTA:</strong> para visualizar el recibo en Preview, click en <strong>Guardar Cambios</strong>.
                </div>
                <div class="tabbable-custom boxless tabbable-reversed">
                    <ul class="nav nav-tabs" id="d_tabs">
                        <li class="active"><a href="#tab_0" data-toggle="tab" id="tab_b" v-on:click="verificaCambio()">Nivel 1</a> </li>
                        <li> <a href="#tab_1" data-toggle="tab" v-on:click="verificaCambio()">Nivel 2</a>  </li>                            
                        <li> <a href="#tab_2" data-toggle="tab" v-on:click="verificaCambio()">Preview</a>  </li>
                        <li> <a href="#tab_3" data-toggle="tab" v-on:click="findHistorial()">Historial</a>  </li>                            
                    </ul>
                    <div class="tab-content" id="c_tabs">
                        <div class="tab-pane active" id="tab_0">
                            <div class="portlet-body">
                                <div class="row">
                                    <reciboComponent>                       
                                        <template slot="solicitante_nombre">
                                            <span class="impresaltado" colspan ="3">{{etiqueta_nombre}}:</span> 
                                            <span class="">
                                                <div class="btn-group">
                                                    <dropdownmenut v-if="true">
                                                        <template slot="a_link" >
                                                            <a data-pk="1" class="dropdown-toggle" data-toggle="dropdown" v-on:click="a_nombre(1)">{{recibo_nombre}}</a>
                                                        </template>
                                                        <template slot="title"> <strong>Selecciona una opcion</strong></template>
                                                        <template slot="group_input"> 
                                                            <select2 class="form-control input-large" :options="options" v-model="value_nombre">
                                                                <option v-for="b in data_nombre" :value="b.value">{{b.name}}</option>
                                                            </select2>
                                                        </template>
                                                        <template slot="group_button"> <button class="btn blue" v-on:click="a_nombre(0)"><i class="fa fa-check"></i></button></template>
                                                    </dropdownmenut>
                                                </div>                                        
                                            </span>
                                        </template>                          
                                        <template slot="solicitante_rfc">
                                            <span class="impresaltado" colspan ="3">RFC:</span> 
                                            <span class="">
                                                <div class="btn-group">
                                                    <dropdownmenut v-if="true">
                                                        <template slot="a_link">
                                                            <a data-pk="1" class="dropdown-toggle" data-toggle="dropdown" v-on:click="a_rfc(1)">{{recibo_rfc}}</a>
                                                        </template>
                                                        <template slot="title"><strong>Selecciona una opcion</strong></template>
                                                        <template slot="group_input"> 
                                                            <select2 class="form-control input-large" :options="options" v-model="value_rfc">
                                                                <option v-for="b in data_rfc" :value="b.value">{{b.name}}</option>
                                                            </select2>
                                                        </template>
                                                        <template slot="group_button"> <button class="btn blue" v-on:click="a_rfc(0)"><i class="fa fa-check"></i></button></template>
                                                    </dropdownmenut>
                                                </div>
                                            </span>
                                        </template>                          
                                        <template slot="detalle">
                                            <div class="btn-group">
                                                <dropdownmenut>
                                                    <template slot="a_link">
                                                        <a data-pk="1" class="dropdown-toggle" data-toggle="dropdown" v-on:click="a_detalle(1)">{{ nombre_tramite }} {{ recibo_detalle_campos }}</a>
                                                    </template>
                                                    <template slot="title"> <strong>Selecciona los detalles</strong></template>
                                                    <template slot="group_input">
                                                        <div class="form-group">
                                                            <label style="font-weight: bold;">Campos</label>
                                                            <multiselect  v-model="value_campos_grupos" :options="campos_grupos" label="grupo_tramite" track-by="identificador" 
                                                            :searchable="true" :multiple="true" placeholder="Selecciona una  opción" class="custom-multiselect"></multiselect>
                                                        </div>  
                                                    </template>
                                                    <template slot="group_button"> <button class="btn blue" v-on:click="a_detalle(0)" ><i class="fa fa-check"></i></button></template>
                                                </dropdownmenut>
                                            </div>
                                        </template>
                                    </reciboComponent>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab_1">
                            <div class="portlet-body">
                                <div class="row">
                                    <reciboComponent>                       
                                        <template slot="solicitante_nombre">
                                            <span class="impresaltado" colspan ="3">{{etiqueta_nombre}}:</span> 
                                            <span class="font-red"> {{recibo_nombre}} </span>
                                        </template>                          
                                        <template slot="solicitante_rfc">
                                            <span class="impresaltado" colspan ="3">RFC:</span> 
                                            <span class="font-red"> {{recibo_rfc}} </span>
                                        </template> 
                                        <template slot="detalle_btn">
                                            <span class="help-block">&nbsp;</span>
                                            <div class="text-center">
                                                <div class="btn-group dropup ">
                                                    <dropdownmenut v-if="true">
                                                        <template slot="a_link" >
                                                            <a data-pk="1" class="dropdown-toggle btn green btn-icon" data-toggle="dropdown"><i class=" fa fa-plus"></i></a>
                                                        </template>
                                                        <template slot="title"> <strong>Agregar detalle</strong></template>
                                                        <template slot="group_input">
                                                            <br> 
                                                            <div class="form-group">
                                                                <label style="font-weight: bold;">Tipo </label>
                                                                <select2 class="form-control input-large" :options="options" v-model="tipo_lv2"  @eventchange="changeTipo()">
                                                                    <option :value="'fijo.'">Campo personalizado</option>
                                                                    <option :value="'info.'">Campos del tramite</option>
                                                                    <option :value="'base.'">Campos base de datos</option>
                                                                </select2>
                                                            </div>
                                                            <div class="form-group" v-if="hidden_valor">
                                                                <label style="font-weight: bold;">Etiqueta </label>
                                                                <input type="text" name="city" class="form-control input-large" autocomplete="off" v-model="value_lv2" placeholder="Ingresa Valor">
                                                            </div>
                                                            <div class="form-group" v-if="hidden_base">
                                                                <label style="font-weight: bold;">Etiqueta </label>
                                                                <input type="text" name="etiqueta" class="form-control input-large" autocomplete="off" v-model="value_base_etiqueta_lv2" placeholder="Ingresa Etiqueta">
                                                            </div>
                                                            <div class="form-group" v-if="hidden_base">
                                                                <label style="font-weight: bold;">Campo de base de datos </label>
                                                                <select2 class="form-control input-large" :options="options" v-model="value_base_lv2">
                                                                    <option :value="''"> </option>
                                                                    <option v-for="b in data_base_lv2" :value="b.value">{{b.name}}</option>
                                                                </select2>
                                                            </div>
                                                            <div class="form-group" v-if="hidden_campo">
                                                                <label style="font-weight: bold;">Agrupación del trámite </label>
                                                                <select2 class="form-control input-large" :options="options" v-model="grupo_lv2" @eventchange="findCampos()">
                                                                    <option :value="''"> </option>
                                                                    <option v-for="b in grupos" :value="b.id">{{b.descripcion}}</option>
                                                                </select2>
                                                            </div>
                                                            <div class="form-group" v-if="hidden_campo">
                                                                <label style="font-weight: bold;">Campos </label>
                                                                <select2 class="form-control input-large" :options="options" v-model="campo_lv2" >
                                                                    <option :value="''"> </option>
                                                                    <option v-for="b in campos" :value="b.campo_id">
                                                                        {{b.campo_nombre}} {{ b.etiqueta.length==0 ? '' : ' - '+ b.etiqueta }}</option>
                                                                </select2>
                                                            </div>
                                                        </template>
                                                        <template slot="group_button"> 
                                                            <div class="form-group">
                                                                <button class="btn blue" v-on:click="add_item_detalle(1)"><i class="fa fa-check"></i></button>
                                                            </div>
                                                        </template>                                                        
                                                    </dropdownmenut>
                                                </div> 
                                            </div> 
                                        </template>                        
                                        <template slot="detalle">
                                            <span class="font-red">
                                                {{ nombre_tramite }} {{ recibo_detalle_campos }}
                                            </span> <br>
                                        </template>
                                        <template slot="detalle_label">
                                            <br>
                                            <ul id="sortableOrden" class="sortable"style="cursor: -webkit-grab; cursor: grab;">
                                                <li class='ui-state-default' v-for="(vd, index) in value_detalle_lv2" > 
                                                    <span class='ui-icon ui-icon-arrowthick-2-n-s'></span>
                                                    <input type='checkbox' name='iAgrupacion' :value='JSON.stringify(vd)' style="display:none">
                                                    {{ vd.etiqueta==null ? '' : vd.etiqueta.length==0 ? '' : vd.etiqueta + ' : ' }} {{ vd.mostrar }} 
                                                    <a class="btn red btn-sm" style="float: right;" v-on:click="deleteEvent(index)"> <i class="fa fa-trash"></i></a>
                                                </li>
                                             </ul>
                                             <br>   
                                        </template>
                                        <template slot="codigo_barras">
                                            <p align="center" >
                                                <span>
                                                    <div class="btn-group dropup">
                                                        <dropdownmenut v-if="true">
                                                            <template slot="a_link">
                                                                <a data-pk="1" class="dropdown-toggle" data-toggle="dropdown" v-on:click="a_barras(1)">Codigo de barras: {{recibo_barras}}</a>
                                                            </template>
                                                            <template slot="title"><strong>Selecciona una opcion</strong></template>
                                                            <template slot="group_input"> 
                                                                <select2 class="form-control input-large" :options="options" v-model="value_barras">
                                                                    <option v-for="b in data_barras" :value="b.value">{{b.name}}</option>
                                                                </select2>
                                                            </template>
                                                            <template slot="group_button">
                                                                <div class="form-group">
                                                                    <button class="btn blue" v-on:click="a_barras(0)"><i class="fa fa-check"></i></button>
                                                                </div> 
                                                            </template>
                                                        </dropdownmenut>
                                                    </div>
                                                </span>
                                            </p>
                                        </template>
                                    </reciboComponent>
                                </div>
                                <a data-pk="1" class="dropdown-toggle" data-toggle="dropdown"></a>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab_2">
                            <div class="portlet-body blockui_sample_3_2_element">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="portlet light bordered">
                                            <div class="portlet-title">
                                                <div class="col-md-7">
                                                    <div class="form-group form-md-line-input has-info form-md-floating-label">
                                                        <div class="input-group input-group-md">
                                                            <div class="input-group-control input-icon right">
                                                                <input type="text" class="form-control input-md valida-numero" v-model="folio" v-on:keyup.enter="findFolioTramite()">
                                                                <label for="form_control_1">Folio</label>
                                                                <span class="help-block">Presiona ENTER para buscar...</span>
                                                                <i v-if="hidde_input" class="fa fa-times" style="cursor: pointer;" v-on:click="cInput()" title="Limpiar folio"></i>
                                                            </div>
                                                            <span class="input-group-btn btn-right">
                                                            <button class="btn green-haze" type="button" v-on:click="findFolioTramite()"><i class="fa fa-search"></i> Preview </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="portlet-body">
                                                <div class="row">
                                                    <div clase="col-md-12" v-if="recibo_estatus==0"> </div>
                                                    <div clase="col-md-12" v-if="recibo_estatus==400">
                                                        <div class="alert alert-warning alert-dismissable">
                                                            <center> <h4>Folio no pertenece al tramite </h4> </center>
                                                        </div>
                                                    </div>
                                                    <div clase="col-md-12" v-if="recibo_estatus==200">
                                                        <iframe :src="iframeSrc" @load="load" v-show="loaded" ref="frame" width="100%" height="100%" title="recibo" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                                    </div>                                                    
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                        <div class="tab-pane" id="tab_3">
                            <div class="portlet-body blockui_sample_3_2_element">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="portlet light bordered">
                                            <div class="portlet-body">
                                                <div class="row">
                                                    <tableHistorial :lengthMenu="lengthMenu" @reciboPublicado="verRecibo" :data_="data_historial" :id="'sample_'" v-if="renderHistorial"/>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>

            </template>
            <template slot="modal-footer">
                <button class="btn green"  v-on:click="savePreview()"><i class="fa fa-check"></i> Guardar Cambios</button>
                <button class="btn green" href="#portlet-confirmar" data-toggle='modal'><i class="fa fa-check"></i> Publicar en producción</button>
                <button type="button" class="btn default"  data-dismiss="modal" v-on:click="closeModal()">Cerrar</button>
            </template>
        </modal>
        <modal :id="'modal-historial'" :modal="'modal-lg'">      
            <template slot="modal-header">
                <button type="button" class="close" aria-hidden="true" data-dismiss="modal"></button>
                <h4 class="modal-title"><i class="fa fa-file-code-o"></i> Recibo - <label style="color: #cb5a5e;">{{ config_historial.fecha }} - {{ config_historial.usuario }}</label></h4>
            </template>
            <template slot="modal-body">
                <div class="tabbable-custom boxless tabbable-reversed">
                    <div class="portlet-body">
                        <div class="row">
                            <reciboComponent>                       
                                <template slot="solicitante_nombre">
                                    <span class="impresaltado" colspan ="3">{{detalle_historial.nivel_1.solicitante[0].etiqueta}}:</span> 
                                    <span class="font-red"> {{detalle_historial.nivel_1.solicitante[0].name}} </span>
                                </template>                          
                                <template slot="solicitante_rfc">
                                    <span class="impresaltado" colspan ="3">RFC:</span> 
                                    <span class="font-red"> {{detalle_historial.nivel_1.solicitante[1].name}} </span>
                                </template>                        
                                <template slot="detalle">
                                    <span class="font-red">
                                        {{ nombre_tramite }} {{ typeof(detalle_historial.nivel_1.titulo_tramite_campos[0])=='undefined' ? '': ', ' + detalle_historial.nivel_1.titulo_tramite_campos.map(item => item.name).join(', ') }}
                                    </span> <br>
                                </template>
                                <template slot="detalle_label">
                                    <br>
                                    <div v-for="(vd, index) in detalle_historial.nivel_2.detalle_tramite">
                                        <label> {{ vd.etiqueta==null ? '' : vd.etiqueta.length==0 ? '' : vd.etiqueta + ' : ' }} {{ vd.mostrar }} </label>
                                    </div>
                                    <br>
                                </template>
                                <template slot="codigo_barras">
                                    <p align="center" >
                                        <span class="font-red"> Codigo de barras: {{detalle_historial.nivel_2.codigo_barras_name}} </span>
                                    </p>
                                </template>
                            </reciboComponent>
                        </div>
                        <a data-pk="1" class="dropdown-toggle" data-toggle="dropdown"></a>
                    </div>
                </div>
            </template>
            <template slot="modal-footer">
                <button type="button" class="btn default" data-dismiss="modal">Cerrar</button>
            </template>
        </modal>
        <modalconfirmation :id="'portlet-confirmar'" >      
            <template slot="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Confirmar</h4>
            </template>
            <template slot="modal-body">
                ¿Estas seguro de <label style="color: #cb5a5e;">APLICAR</label> los cambios?
            </template>
            <template slot="modal-footer">
                <button type="button" data-dismiss="modal" class="btn default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn green" v-on:click="saveConfig()"><i class="fa fa-check"></i> Aplicar Cambios</button>
            </template>
        </modalconfirmation>
        <modalconfirmation :id="'portlet-cerrar'" >      
            <template slot="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Confirmar</h4>
            </template>
            <template slot="modal-body">
                ¿Estas seguro de <label style="color: #cb5a5e;">CERRAR</label> sin aplicar los cambios en <strong> Producción</strong>?
            </template>
            <template slot="modal-footer">
                <button type="button" data-dismiss="modal" class="btn default">Regresar </button>
                <button type="button" data-dismiss="modal" class="btn green" v-on:click="closeRecibo()"><i class="fa fa-times"></i> Cerrar sin guardar</button>
            </template>
        </modalconfirmation>
    </div>
</template>
  
<script>  
    import axios from 'axios'
    import VCalendar from 'v-calendar' 
    import multiselect from 'vue-multiselect'
    import select2 from './General/Select.vue'
    import modalconfirmation from './General/ModalConfirmation.vue'
    import modal from './General/Modal.vue'
    import portlet from './General/Portlet.vue'
    import dropdownmenut from './General/DropdownMenu.vue'
    import reciboComponent from './Individual/Recibo.vue'
    import tableSample from './Individual/TableSampleRecibo.vue'
    import tableHistorial from './Individual/TableHistorialRecibo.vue'
   
    export default {
        name: 'ConfiguracioRecibos',
        components: {VCalendar,multiselect,select2,modalconfirmation,portlet,modal,dropdownmenut,reciboComponent,tableSample,tableHistorial},
        data(){
            return{
                recibo_nombre:'No mostrar', 
                etiqueta_nombre:'NOMBRE', 
                recibo_rfc:'No mostrar', 
                recibo_detalle_campos:'',
                recibo_barras:'No mostrar',
                options:'',
                value_nombre:'',  
                value_rfc:'',
                value_barras:'',
                data_nombre:[],
                data_rfc:[],
                data_barras:[],
                data_base_lv2:[],
                value_base_etiqueta_lv2:'',
                value_detalle_lv2:[],
                tipo_lv2:'fijo.',
                value_lv2:'',
                value_base_lv2:'',
                campo_lv2:'',
                grupo_lv2:'',
                grupos:[],
                campos:[],
                servicios:[],
                campos_grupos:[],              
                value_campos_grupos:[],            
                const_campos_grupos:[],            
                servicio_id:'',
                hidden_campo:false,
                hidden_valor:true,
                hidden_base:false,
                renderTable:false,
                folio:'',
                recibo_estatus:0,
                loaded:false,
                iframeSrc:'',
                hidde_input:false,
                hidden_alert:false,
                lengthMenu:[[10, 25, 50, -1],[10, 25, 50, 'All']],
                oldConfig:[],
                nombre_tramite:'',
                renderHistorial:false,
                data_historial:[],
                config_historial:[],
                detalle_historial:{nivel_1:{
                    solicitante:[{
                        etiqueta:"",
                        name:""
                    },{
                        name:""
                    }],
                    titulo_tramite_campos:[{
                        id:"",
                        name:"",
                        agrupacion_id:"",
                        grupo:"",
                        identificador:"",
                        grupo_tramite:""
                    }]
                    },
                    nivel_2:{
                        detalle_tramite:[],
                        codigo_barras:"",
                        codigo_barras_name:""
                    }
                }
            }        
        },
        methods:{
            a_nombre(tipo){
                let value= tipo==1 ? this.recibo_nombre : this.value_nombre;
                let item= tipo==1 ? 'name' : 'value';

                const filterList= this.data_filter(this.data_nombre,item,value);
                this.recibo_nombre=filterList[0]['name']; 
                this.etiqueta_nombre=filterList[0]['etiqueta'];
                this.value_nombre=filterList[0]['value'];
                $("a.dropdown-toggle").dropdown("toggle");
               //if(tipo==0){ this.savePreview();}
            },
            a_rfc(tipo){
               let value= tipo==1 ? this.recibo_rfc : this.value_rfc;
               let item= tipo==1 ? 'name' :'value';

                const filterList = this.data_filter(this.data_rfc,item,value);
                this.recibo_rfc=filterList[0]['name'];
                this.value_rfc=filterList[0]['value'];
                $("a.dropdown-toggle").dropdown("toggle");
                //if(tipo==0){ this.savePreview();}
            },
            a_detalle(tipo){
               let campos= tipo==1 ? this.const_campos_grupos :this.value_campos_grupos

                this.value_campos_grupos=campos;
                this.const_campos_grupos=campos;
                this.recibo_detalle_campos= this.const_campos_grupos.length==0 ? '': ', ' + this.const_campos_grupos.map(item => item.name).join(', ');
                $("a.dropdown-toggle").dropdown("toggle");
                //if(tipo==0){ this.savePreview();}
            },
            a_barras(tipo){
               let value= tipo==1 ? this.recibo_barras : this.value_barras;
               let item= tipo==1 ? 'name' :'value';
               
                const filterList = this.data_filter(this.data_barras,item,value);
                this.recibo_barras=filterList[0]['name'];
                this.value_barras=filterList[0]['value'];
                $("a.dropdown-toggle").dropdown("toggle");
                //if(tipo==0){ this.savePreview();}
            },
            add_item_detalle(){
                let  etiqueta=null;
                let item={'etiqueta':etiqueta, 'value': 'fijo.' + this.value_lv2, 'mostrar':this.value_lv2, 'agrupacion_id':0};
                let campos_=this.campos;
                if(this.tipo_lv2=='base.'){
                    let baseList = this.data_base_lv2.filter((e) => e['value'].toString() === this.value_base_lv2.toString()).map((e) => { return e});
                    item={'etiqueta':this.value_base_etiqueta_lv2, 'value': 'base.' + this.value_base_lv2, 'mostrar':baseList[0]['name'], 'agrupacion_id':0};
                }
                if(this.tipo_lv2=='info.'){
                    let filterList = campos_.filter((e) => e['campo_id'].toString() === this.campo_lv2.toString()).map((e) => { return e});
                    //console.log([0]['etiqueta'])
                    if(filterList[0]['etiqueta']!=''){
                        etiqueta=filterList[0]['etiqueta'];
                    }else{ etiqueta=filterList[0]['campo_nombre']; }
                    item={'etiqueta':etiqueta, 'value': 'info.' + this.campo_lv2, 'mostrar': filterList[0]['campo_nombre'], 'agrupacion_id':this.grupo_lv2};
                }
                let ex=this.existsInList(item);
                console.log(ex);
                if(!ex){
                   this.value_detalle_lv2.push(item); 
                }else{
                    this.notifications("400","Ya se encuentra agregado!");
                }
                this.etiqueta_lv2='';
                this.value_lv2='';
                this.campo_lv2='';
                this.value_base_etiqueta_lv2='';
                this.value_base_lv2='';
                $("a.dropdown-toggle").dropdown("toggle");
               // this.savePreview();
            },
            existsInList(obj) {
                return this.value_detalle_lv2.some(item => JSON.stringify(item) === JSON.stringify(obj));
            },
            data_filter(data,item,value){
                const filterList = data.filter((e) => e[item] === value).map((e) => { return e});
                return filterList;
            },
            deleteEvent(index){
                this.value_detalle_lv2.splice(index, 1);
                //this.savePreview();
            },
            changeTipo(){
                this.hidden_campo=false;
                this.hidden_valor=false;
                this.hidden_base=false;
                if(this.tipo_lv2=='fijo.'){ 
                    this.hidden_valor=true;
                }else if(this.tipo_lv2=='base.'){
                    this.hidden_base=true
                }
                else{this.hidden_campo=true; this.findGrupos();}
            },
            findCatalogo(){
                axios.get(`${process.env.APP_URL}/catalogo-recibos`)
                    .then((response)=>{           
                        let data=response.data.response;
                        this.data_nombre=JSON.parse(data.solicitante_contribuyente);
                        this.data_rfc= JSON.parse(data.solicitante_rfc);
                        this.data_detalle= JSON.parse(data.detalle);
                        this.data_barras=JSON.parse(data.codigo_barras);
                        this.data_base_lv2=JSON.parse(data.campos_db);
                    })
                    .catch(error=>{
                        this.notifications("400","Error al descargar");
                        console.log(error);
                    }
                );
            },
            cInput(){
                this.folio='';
                this.hidde_input=false;
            },
            findFolioTramite(){
                this.loagingC(true);
                 this.recibo_estatus=0;
                if(this.folio.length<8 || this.folio.length>30){
                    this.notifications("400","Folio, Logitud");
                    this.recibo_estatus=0;
                    this.loagingC(false);
                    return;
                }
                let json_config= this.json_config();
                axios.post(`${process.env.APP_URL}/valida-folio`,{
                    id_tipo_servicio:this.servicio_id,
                    folio:this.folio
                    }).then((response)=>{           
                        this.recibo_estatus=response.data.status;
                        this.iframeLoad();
                        this.loagingC(false);
                    }).catch(error=>{
                        this.notifications("400","Error al descargar");
                        console.log(error);
                        this.recibo_estatus="400";
                        this.loagingC(false);
                    }
                );
            },
            savePreview(){
                let json_config= this.json_config();

                axios.post(`${process.env.APP_URL}/save-preview`,{
                    id_tipo_servicio:this.servicio_id,
                    detalle: json_config
                    }).then((response)=>{           
                        this.notifications(response.data.status,response.data.message);
                    }).catch(error=>{
                        this.notifications("400","Error al descargar");
                        console.log(error);
                    }
                );
            },
            findServicios(){
                this.renderTable=false;
                this.loagingC(true);                
                axios.get(`${process.env.APP_URL}/find-tramite-recibo`)
                    .then((response)=>{ 
                        this.loagingC(false);          
                        this.servicios=response.data.response;
                        this.renderTable=true; 
                        
                    })
                    .catch(error=>{
                        this.notifications("400","Error al descargar");
                        console.log(error);
                        this.loagingC(false);
                    }
                );
            },
            findHistorial(){
                this.renderHistorial=false;
                axios.post(`${process.env.APP_URL}/historial-recibos`,{
                    id_tipo_servicio:this.servicio_id
                    })
                    .then((response)=>{         
                        this.data_historial=response.data.response;
                        this.renderHistorial=true;
                    })
                    .catch(error=>{
                        this.notifications("400","Error al descargar");
                        console.log(error);
                    }
                );
            },
            verRecibo(data){
                this.config_historial=data;
                this.detalle_historial=JSON.parse(data.detalle);
                //console.log(this.detalle_historial);
            },
            findCampos(){                
                axios.post(`${process.env.APP_URL}/traux-get-relcamp`,{
                    tramiteid: this.servicio_id,
                    agrupacion_id: this.grupo_lv2
                    })
                    .then((response)=>{                            
                       let data=[];
                        response.data.forEach(d => {
                            const e=JSON.parse(d.caracteristicas);
                            d['etiqueta']= typeof(e.etiqueta)==='undefined' ? '': e.etiqueta;
                            data.push(d);
                        });
                        this.campos=data;
                    })
                    .catch(error=>{
                        this.notifications("400","Error al descargar");
                        console.log(error);
                    }
                );
            },
            array_object(data,tipo){
                let new_data=[];
                data.forEach(d => {
                    if(typeof(new_data[d.agrupacion_id])== 'undefined'){
                        new_data[d.agrupacion_id]={'group': d.grupo , 'options':[d]};
                    }else{
                        new_data[d.agrupacion_id].options.push(d);
                    }                    
                });
                return new_data;
            },
            array_object_(data){
                let new_data=[];
                data.forEach(d => {
                    if(typeof(new_data[d.agrupacion_id])== 'undefined'){
                        new_data[d.agrupacion_id]=[d];
                    }else{
                        new_data[d.agrupacion_id].push(d);
                    }                    
                });
                return new_data;
            },
            findCamposGrupos(){
                axios.post(`${process.env.APP_URL}/campos-tramite`,{
                    id_tipo_servicio: this.servicio_id
                    })
                    .then((response)=>{ 
                        //this.campos_grupos = this.array_object(response.data.response);
                        this.campos_grupos = response.data.response;
                    })
                    .catch(error=>{
                        this.notifications("400","Error al descargar");
                        console.log(error);
                    }
                );
            },
            findGrupos(){
                axios.post(`${process.env.APP_URL}/traux-agrupacion`,{
                    id_tramite: this.servicio_id
                    })
                    .then((response)=>{           
                        this.grupos=response.data;
                    })
                    .catch(error=>{
                        this.notifications("400","Error al descargar");
                        console.log(error);
                    }
                );
            },
            accionRecibo(data_){
                this.cleanInput();
                this.servicio_id=data_.id;
                this.nombre_tramite=data_.desc;
                //this.findGrupos();
                this.findCamposGrupos();
                this.oldConfig=[];
                $("#tab_b").click();
                this.jsonConfiguracion(data_);
                if(data_.detalle !="" && data_.detalle !=null){
                    this.oldConfig=JSON.parse(data_.detalle);
                }else{this.oldConfig=[];}
                this.verificaCambio();
            },
            iframeLoad(){
                if( this.recibo_estatus==200){
                    this.loaded=true;
                    this.iframeSrc= `${process.env.URL_FORMATO_RECIBO_PREVIEW}`+ this.folio;                    
                }
            },
            jsonConfiguracion(data_){
                if(data_.preview==null && data_.detalle==null){
                    return;
                }
                let data=[];
                if(data_.detalle !=""){
                    data=JSON.parse(data_.detalle);
                }else{
                    data=JSON.parse(data_.preview);
                }

                this.recibo_nombre=data.nivel_1.solicitante[0].name;
                this.etiqueta_nombre=data.nivel_1.solicitante[0].etiqueta;
                this.recibo_rfc=data.nivel_1.solicitante[1].name;
                this.value_campos_grupos=data.nivel_1.titulo_tramite_campos;
                this.recibo_barras=data.nivel_2.codigo_barras_name;
                this.value_nombre=data.nivel_1.solicitante[0].valor;
                this.value_rfc=data.nivel_1.solicitante[1].valor;
                this.value_barras=data.nivel_2.codigo_barras;
                this.value_detalle_lv2=data.nivel_2.detalle_tramite;
                this.const_campos_grupos=data.nivel_1.titulo_tramite_campos;
                this.recibo_detalle_campos= this.const_campos_grupos.length==0 ? '': ', ' + this.const_campos_grupos.map(item => item.name).join(', ');
            },
            jsonConfigHistorial(data_){
                if(data_.detalle==null){return;}
                let data=JSON.parse(data_.detalle);
                this.recibo_nombre=data.nivel_1.solicitante[0].name;
                this.etiqueta_nombre=data.nivel_1.solicitante[0].etiqueta;
                this.recibo_rfc=data.nivel_1.solicitante[1].name;
                this.value_campos_grupos=data.nivel_1.titulo_tramite_campos;
                this.recibo_barras=data.nivel_2.codigo_barras_name;
                this.value_nombre=data.nivel_1.solicitante[0].valor;
                this.value_rfc=data.nivel_1.solicitante[1].valor;
                this.value_barras=data.nivel_2.codigo_barras;
                this.value_detalle_lv2=data.nivel_2.detalle_tramite;
                this.const_campos_grupos=data.nivel_1.titulo_tramite_campos;
                this.recibo_detalle_campos= this.const_campos_grupos.length==0 ? '': ', ' + this.const_campos_grupos.map(item => item.name).join(', ');
            },
            json_config(){
                let json_config={
                    "nivel_1":{
                        "solicitante":[
                            {
                                "etiqueta":this.etiqueta_nombre,
                                "valor":this.value_nombre,
                                "name":this.recibo_nombre
                            },
                            {
                                "etiqueta":"RFC",
                                "valor": this.value_rfc,
                                "name": this.recibo_rfc
                            },
                        ],
                        "titulo_tramite_campos": this.value_campos_grupos
                    },
                    "nivel_2":{
                        "detalle_tramite":this.value_detalle_lv2,
                        "codigo_barras": this.value_barras,
                        "codigo_barras_name": this.recibo_barras
                    }
                }
                return json_config;
            },
            saveConfig(){
                this.loagingC(true);
                let json_config= this.json_config();
                this.oldConfig= json_config;
                axios.post(`${process.env.APP_URL}/save-recibo`,{
                    id_tipo_servicio: this.servicio_id,
                    detalle: json_config
                    })
                    .then((response)=>{           
                        this.campos=response.data.response;
                        this.notifications(response.data.status,response.data.message);
                        this.findServicios();
                        this.loagingC(false);
                        this.hidden_alert=false;
                    })
                    .catch(error=>{
                        this.notifications("400","Error al descargar");
                        console.log(error);
                        this.loagingC(false);
                    }
                );
            },
            verificaCambio(){
                this.hidden_alert=false;
                let newConfig= this.json_config();
                let oldC=this.oldConfig;
                if( JSON.stringify(oldC)!==JSON.stringify(newConfig)){ 
                    this.hidden_alert=true;
                }
                return this.hidden_alert;
            },
            notifications(Code,Message){
                if(Code=="200"){
                    Command: toastr.success(Message, "Notifications");
                }else{
                    Command: toastr.warning(Message, "Notifications");
                }
            },
            loagingC(value, index, values) {
                return Vue.filter('loadingCharge')(value);
            },
            closeModal(){
            //   let modal= this.verificaCambio();
            //   if(modal){
            //     $("#portlet-cerrar").modal('show');
            //   }else{$("#modal-recibo").modal('hide');}
            },
            closeRecibo(){
                $("#modal-recibo").modal('hide');
                this.findServicios();
                this.cleanInput();                
            },
            cleanInput(){
                this.recibo_nombre='No mostrar';
                this.etiqueta_nombre='NOMBRE';
                this.recibo_rfc='No mostrar';
                this.recibo_barras='No mostrar';
                this.recibo_detalle_campos="";
                this.options='';
                this.value_nombre='no_mostrar';
                this.value_rfc='no_mostrar';
                this.value_barras='no_mostrar';
                this.value_detalle_lv2=[];
                this.etiqueta_lv2='';
                this.tipo_lv2='fijo.';
                this.value_lv2='';
                this.grupo_lv2='';
                this.campo_lv2='';
                this.value_base_lv2='';
                this.value_base_etiqueta_lv2='';
                this.campos=[];
                this.value_campos_grupos=[];
                this.hidden_campo=false;
                this.hidden_valor=true;
                this.hidden_base=false;
                this.const_campos_grupos=[];
                this.folio='';
                this.recibo_estatus=0;
                this.loaded=false;
                this.iframeSrc='';
                this.oldConfig=[];
                this.hidden_alert=false;
                this.nombre_tramite='';
            },
            load: function(){
                this.loaded = true;
            },
        },
        watch: {
            folio(folioN) {
                this.folio = folioN.replace(/[^0-9.]{0,10}$/g,'');
                if(folioN.length>0){ this.hidde_input=true; }else{ this.hidde_input=false;  }
            },
            value_detalle_lv2(value_detalle_lv2){
                $( "#sortableOrden" ).sortable();
                $( "#sortableOrden" ).disableSelection();
                $( "#sortableOrden" ).sortable({
                    update: function( event, ui ) {
                        let new_data=[];
                        $("input:checkbox[name=iAgrupacion]:unchecked").each(function(){
                            if($(this).val() !="on"){
                                new_data.push( JSON.parse($(this).val()));
                            }
                        });
                        this.value_detalle_lv2=new_data;
                    }
                });
            }
        },
        mounted() {
            this.findCatalogo();
            this.findServicios(); 
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .multiselect {
        width:320px !important;
    }
    .multiselect__option--group
    {
        background:#ffffff!important;
        color:#35495e !important;
        font-weight: bold !important;
    }
    .multiselect__tags {
    border: 1px solid #e4e8eb;
    background: #fff;
    }
    .multiselect__option--selected{
        background: #f7f7f7;
    }
    .multiselect__tags:focus-within {
    animation-duration: 0.15s;
    animation-name: ease-in-out;
    outline: #e5e5e5 solid 2px;
    border: 1px solid #000;
    }

    .multiselect__content-wrapper {
    animation-duration: 0.15s;
    animation-name: ease-in-out;
    border-color: #000;
    outline: #e5e5e5 solid 2px;
    }

    @keyframes ease-in-out {
    from {
        outline: #e5e5e5 solid 0px;
    }

    to {
        outline: #e5e5e5 solid 4px;
    }
    }

</style>