<template>
    <div class="fileinput fileinput-new" data-provides="fileinput">                       
        <span class="btn green btn-file">
        <span class="fileinput-new">
        <i class="fa fa-plus"></i>&nbsp; {{ textoInput }}</span>
        <span class="fileinput-exists">
        <i class="fa fa-exchange"></i>&nbsp; &nbsp; {{  textoChange }} </span>
        <input type="file" name="file" :accept="accept" id="file" v-on:change="inputFile( $event )">
        </span>
        <button type="submit" v-if="guardar" class="btn blue fileinput-exists enableBtn" v-on:click="uploadFile()"><i class="fa fa-check"></i> Guardar</button>
        <span class="fileinput-filename" style="text-overflow: ellipsis;width: 240px;overflow: hidden; white-space: nowrap;">
        </span>&nbsp; <a href="javascript:;" class="close fileinput-exists delFilePDF" data-dismiss="fileinput"id="delFilePDF" v-on:click="cancelaArchivo()">
        </a>          
    </div>
</template>
<script >
  export default {
    name:"btnFile",
    props: ['textoInput','textoChange','accept','guardar'],
    methods:{
      inputFile(event){
        let file = event.target.files[0];
        this.$emit('inputFile', file);
      },
      uploadFile(){
        this.$emit('uploadFile');
      },
      cancelaArchivo(){
        this.$emit('cancelaArchivo');
        this.$emit('inputFile', '');
      },
    } 
  }
</script>