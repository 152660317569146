<template>
    <div>
        <div class="col-md-1"> </div>         
        <div class="col-md-11"> 
            <table height="8" cellspacing="2" cellpadding="0" width="630" bgcolor="white" border="0">
                <td><img src="images/logo_tesoreria.png" alt="Logo TesorerÃ­a" style="width: 181px;"></td>
            </table>
            <br/><br/>
            <table height="10" cellspacing="4" cellpadding="0" width="630" bgcolor="white" >
                <tr>
                    <td width="10%" class="impresaltado" colspan="2">MUNICIPIO: </td>
                    <td width="50px">&nbsp;</td>
                    <td class="impresaltado" align="left" colspan="2"><b>FOLIO PAGO:</b> 2000000000</td>
                </tr>
                <tr>
                    <td nowrap class="impnormal" colspan="2">Municipio</td>
                    <td width="50px">&nbsp;</td>
                    <td align="left" class="impresaltado" colspan="2">FSE: 20000000000000</td>
                </tr>
                <tr>
                    <td align="left" width="10%" class="impresaltado">FECHA: </td>
                    <td align="left" class="impnormal">0000-00-00</td>
                    <td width="50px">&nbsp;</td>
                    <td align="left" colspan="2">
                        <span class="impresaltado" >BANCO:</span> 
                        <span class="impnormal" >Banco</span>  
                    </td>
                </tr>
                <tr>
                    <td width="10%" align=left class="impresaltado">estado: </td>
                    <td align="left" class="impnormal">TRAMITE COMPLETO</td>
                </tr>
                <tr><td align="left" colspan ="5" >
                        <span class="impresaltado" colspan ="3">Referencia de pago:</span> 
                        <span class="impnormal">0000000000000000000000000000</span>
                    </td>
                </tr>
                <tr>
                    <td align="left" colspan ="5" >
                        <slot name="solicitante_nombre">

                        </slot>
                    </td>
                </tr>
                <tr>
                    <td align="left" colspan ="5" >
                        <slot name="solicitante_rfc">

                        </slot>
                    </td>
                </tr>
            </table>
            <br>
            <table cellpadding="4" cellspacing="0" rules="cols" border="1" 
                style="background-color:white;border-color:#999999;border-width:1px;border-style:none;width:630px;">
                <tr>
                    <th align="left"  bgcolor="white" >FOLIO</th>
                    <th align="left"  bgcolor="white" colspan = "2">DETALLE</th>
                    <th align="right" bgcolor="white">MONTO</th>
                </tr>
                <tr style="background-color:whitesmoke;color:black;" class="trTitulo_">
                    <td align="left" style="vertical-align: top;" >
                         1000000000
                    </td>
                    <td align="left" colspan = "2" >
                        <slot name="detalle">

                        </slot>
                    </td>
                    <td align="right">$0.00 </td>
                </tr>
                <tr style="background-color:whitesmoke;color:black;" class="trTitulo_">
                    <td align="left" style="vertical-align: top;" >                         
                         <slot name="detalle_btn">

                        </slot>
                    </td>
                    <td align="left" colspan = "2" >
                        <slot name="detalle_label">

                        </slot>
                    </td>
                    <td align="right"></td>
                </tr>
            </table>
            <table cellpadding="3" cellspacing="0"style="background-color:white;border-width:0px;border-style:none;width:630px;">
                <tr>
                    <td width="492" align=right nowrap class="impresaltado">IMPORTE.-</td>
                    <td></td>
                    <td width="106" align=right nowrap class="impresaltado">$0.00</td>
                </tr>
                <tr>
                    <td align=right nowrap class="impresaltado">total.-</td>
                    <td></td>
                    <td align=right nowrap class="impresaltado">$0.00</td>
                </tr>
            </table>
            <table height="10" cellspacing="4" cellpadding="0" width="630" bgcolor="white" border="0">
                <tr>
                    <td>&nbsp</td>
                </tr>
                <tr>
                    <td><p><span class="impresaltado">SISTEMA ELECTRONICO DE PAGO - TESORERIA VIRTUAL CAJA 98</span></p></td>
                </tr>
                <tr><td>&nbsp</td></tr>
                <tr>
                    <td colspan="2" class="ajustar"><span id="lblcifrado" class="impllave" style="background-color:white;height:100%;width:100%;">d429aefd2071bc5af2ddc875f2487646598d74b01585a55885d8b8ac1d820176cb4f8eae80bcc16f59194145ec77073ceb5bd9fbf3aa16ac18b138edb423c8ec3b0b296b32a641d84943708fa9804d1e7b2e1d05f3aab422e4bd6df073f6cb7e55f3f63f0d49f45a9a392891df1645bbfe649b6af5ab93c2748447ae2f2732879718c859a1d6cf9d9223b5adc23657e0f712c4bb2d3114fd8db704f2094ef84acd01b4080def84e9cae39c2e66eae164a3b701822a023dcad71772f6d31577e229dbf8713433d5751e140fa3a01eef55392b49236613d7e355058f367558c00cd08f0f93ade98ae0850d26df61c62be4c10c6fe57d235f008fe44258a376205a2851d32894e8905595efd8ece369d3ded7b3b776223a859363d67c181d6e12b8f519cba409d06616e75d269be2122cdb01727ca0489b0917b5d00a7f2f99a6b68392bf81daf0fe8309f32ff8677cdc117a15648e702c7db12b72f5e454d85a60706d34333ddbec75</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"><p align="center" class="impnormal"><span style="background-color:white;">copia</span></p>
                    </td>
                </tr>
                <tr> 
                    <slot name="codigo_barras">

                    </slot>
                </tr>
                <tr>
                    <td><span class="impresaltado2">obten tu comprobante fiscal digital</span></td>
                </tr>
                <tr>
                    <td><span class="impresaltado2">tu folio es: 000000000000000000000000000000 </span></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr><td>&nbsp;</td></tr>
                <tr><td>&nbsp;</td></tr>
            </table>
        </div>                
    </div>
</template>
<script>
  export default {
    name:"Portlet",
    props: [],
  }
</script>