<template>
  <div>
    <portlet :clase="'container col-md-12  blockui_sample_3_2_element'">
      <template slot="actions" > 
      </template>
      <template slot="caption">                        
        <h4><span class="caption-subject bold uppercase"><i class="fa fa-user"></i> Registro de Usuarios </span></h4>
      </template>
      <template slot="caption2">
        <div class="text-right">
          <a class="btn btn-circle btn-xs btn-primary" data-toggle="modal" href="#portlet-editar" title='Agregar Usuario' v-on:click="nuevoUsuario()"><i class="fa fa-user" ></i> Agregar Usuario</a>
          <a class="btn btn-circle btn-xs btn-default" title='Reporte Homologado' :href="url_general" ><i class="fa fa-file-excel-o"></i> Reporte Homologado</a>
          <a class="btn btn-circle btn-xs btn-default" title='Reporte Tramites.nl' :href="url_portal"><i class="fa fa-file-excel-o"></i> Reporte Tramites.nl</a>
          <a class="btn btn-circle btn-xs btn-default" title='Reporte Admin' :href="url_admin"><i class="fa fa-file-excel-o"></i> Reporte Admin</a>
          <a class="btn btn-circle btn-xs btn-default" title='Reporte Documentos' :href="url_documentos"><i class="fa fa-file-excel-o"></i> Reporte Documentos</a>
          <a href="#modal-conjunto" class="btn btn-circle btn-xs btn-success" data-toggle="modal" title='Conjunto'><i class="fa fa-cogs"></i> Conjuntos</a>     
        </div> 
      </template> 
      <template slot="body"> 
        <div class="row">
          <tableuser :lengthMenu="lengthMenu" @accionStatus="accionStatus"  @accionEditar="accionEditar" @accionDocumentos="accionDocumentos" :data_="data_user" :id="'sample_2'" v-if="renderTable"/>
        </div>
      </template>
    </portlet>

    <FormUser :data_="data_registro"  v-if="renderForm" @formulario="updateFormulario" @loadTable="loadTable"> </FormUser>
        
    <modal :id="'portlet-solicitudes'" :modal="'modal-lg'">      
      <template slot="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" v-on:click="cleanViewF()"></button>
          <h4 class="modal-title"><i class="fa fa-file-pdf-o"></i>  {{ email_user }} - {{ plataforma_descripcion }} (<label style="color: #cb5a5e;">{{ this.tipo_file }}</label>) </h4>
      </template>
      <template slot="modal-body" >           
        <div class="row blockui_sample_3_2_element">
          <div class="col-md-12 ">           
            <iframe :src="iframeSrc" @load="load" v-show="loaded" ref="frame" type="application/pdf" width="100%" height="500px" title="Archivo pdf" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div> 
        </div>
        <hr>
        <div class="row">               
          <div class="col-md-6"> 
            <btnFile :textoInput="'Adjuntar Archivo'" :textoChange ="'Cambiar Archivo'" @cancelaArchivo="cancelaArchivo" @inputFile="inputFile" @uploadFile="uploadFile" :accept="'application/pdf'" :guardar="true"></btnFile>
          </div>
          <div class="col-md-6"> 
            <div class="form-group" id="addurlfile">
              <label style='color: #cb5a5e;'>Descargar Archivo:</label>
                <a :href="href_file" title="Descargar Archivo" >{{fname_download }}...<i class="fa fa-download blue"></i>
              </a>
            </div>
          </div>
          <div class="col-md-12">
            <br>
            <div class="panel-group accordion scrollable" id="accordion2">
              <div class="panel panel-warning">
                <div class="panel-heading">
                  <h4 class="panel-title">
                  <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion2" href="#collapse_2_1" aria-expanded="true">
                  Historial de documentos </a>
                  </h4>
                </div>
                <div id="collapse_2_1" class="panel-collapse in">
                  <div class="panel-body" style="height:200px; overflow-y:auto;">
                    <table class="table ">
                      <thead>
                        <tr>
                          <th>Archivo</th>
                          <th>Fecha</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="df in data_files">
                          <td> <a :href="df.url" title="Descargar Archivo" >{{df.name_original }}...<i class="fa fa-download blue"></i></a></td>
                          <td>{{df.fecha_registro }} </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      </template>
      <template slot="modal-footer">
        <button type="button" data-dismiss="modal" class="btn default" v-on:click="cleanViewF()">Cerrar</button>
      </template>
    </modal>
    <modalconfirmation :id="'portlet-status'" >      
      <template slot="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
        <h4 class="modal-title">Confirmar</h4>
      </template>
      <template slot="modal-body">
        ¿<label style="color: #cb5a5e;">{{ status_descripcion }}</label> usuario <label style="color: #cb5a5e;">{{ usuario_descripcion }}</label> de <label style="color: #cb5a5e;">{{ plataforma_descripcion }}</label>?
      </template>
      <template slot="modal-footer">
        <button type="button" data-dismiss="modal" class="btn default">Cancelar</button>
        <button type="button" data-dismiss="modal" class="btn green" v-on:click="confirmarStatus()"><i class="fa fa-check"></i> Aplicar</button>
      </template>
    </modalconfirmation>
    <modalconjunto></modalconjunto>
  </div>
  
</template>    
<script>
  
  import axios from 'axios'
  import excel from 'vue-excel-export'
  import select2 from './General/Select.vue'
  import modalconfirmation from './General/ModalConfirmation.vue'
  import portlet from './General/Portlet.vue'
  import tableuser from './Individual/TableUser.vue'
  import modal from './General/Modal.vue'
  import btnFile from './General/btnFile.vue'
  import FormUser from './Individual/FormUser.vue'
  import modalconjunto from './Individual/ModalConjunto.vue'

  export default {
    name: 'AdministrarUsuarios',
    components: {select2,modalconfirmation,portlet,excel,tableuser,modal,btnFile,FormUser,modalconjunto},
    data(){
      return{
        data_user:[],
        data_registro:[],
        lengthMenu:[[10, 25, 50, -1],[10, 25, 50, 'All']],
        renderTable:false,
        renderForm:true,
        fname_download:'',
        iframeSrc:'',
        loaded:'',
        href_file:'',
        usuario_descripcion:'',
        plataforma_descripcion:'',
        status_descripcion:'',
        id_user:'',
        id_portal:'',
        status_user:'',
        email_user:'',
        plataforma_user:'',
        tipo_file:'',
        filePdf:'',
        url_general: `${process.env.APP_URL}/reporte-general-user`,
        url_portal: `${process.env.APP_URL}/reporte-portal-user`,
        url_admin: `${process.env.APP_URL}/reporte-admin-user`,
        url_documentos: `${process.env.APP_URL}/reporte-doc-user`,
        data_files:[]
      }            
    },
    methods:{
      updateFormulario(){
        this.renderForm=false;
        this.data_registro={plataforma:'0'};
        this.renderForm=true;
      },
      nuevoUsuario(){
        //console.log(this.data_registro);
        this.renderForm=false;
        this.data_registro=[];
        this.renderForm=true;
      },
      allusers(){
        this.renderTable = false;
        this.loagingC(true);
        axios.get(`${process.env.APP_URL}/modulo-usuarios`)
          .then((response)=>{
            let concatuser=[];
            if(response.data.code=="200"){
              //concatuser=response.data.response.portal;
              concatuser=response.data.response;
              //concatuser=concatuser.concat(response.data.response.admin);
              this.data_user=concatuser;
            }else{
              this.data_user='';
            }
            this.renderTable = true;
            this.loagingC(false);
          })
        .catch(error=>{
          console.log(error);
           this.carga_archivo=true;
           this.loagingC(false);              
        });
      },
      accionDocumentos(tipo_,data){
        this.id_user=data.id;
        this.id_portal="0";
        this.email_user=data.email;
        this.plataforma_user=data.plataforma;
        this.tipo_file=tipo_;
        let pl=data.plataforma;
        if( data.user_portal!=null){
          pl='TRAMITES.NL - ADMINISTRADOR DE LA TESORERIA';
          this.id_portal=data.user_portal.id;
        }
        this.plataforma_descripcion=pl;
        this.loagingC(true);
        this.historialDocumentos();
        this.cleanViewF();
        
        axios.post(`${process.env.APP_URL}/view-file-user`,{
          tipo: tipo_,
          id: data.id,
          id_portal: this.id_portal,
          plataforma: data.plataforma,
        })
        .then((response)=>{
          if(response.data.code=="200"){
            const blob = this.dataURItoBlob(response.data.response.file);
            let uri=URL.createObjectURL(blob);
            this.iframeSrc= uri;
            this.iframeSrcOld=uri;
            this.loagingC(false);
            this.href_file=response.data.response.url;
            this.fname_download=response.data.response.file_name;
            this.loaded=true;
          }
        })
        .catch(error=>{
          console.log(error);
          this.carga_archivo=true;
          this.cleanViewF();
          this.loagingC(false);  
        });
      },
      cleanViewF(){
        this.iframeSrc= '';
        this.iframeSrcOld='';
        this.href_file='';
        this.fname_download='';
        $(".delFilePDF").click(); 
      },
      uploadFile(){
        if(this.filePdf.length==0){ this.notifications("400","Archivo, requerido!"); return false;}
        let formData = new FormData();
        
        formData.append("tipo",this.tipo_file);
        formData.append("user_id",this.id_user);
        formData.append("id_portal",this.id_portal);
        formData.append("plataforma",this.plataforma_user);
        formData.append('file', this.filePdf);

        axios.post(`${process.env.APP_URL}/upload-file-user`,formData , {headers: {'Content-Type': 'multipart/form-data'} })
        .then((response)=>{
          this.notifications(response.data.code,response.data.message);
          if(response.data.code=="200"){
            this.iframeSrcOld= this.iframeSrc;
            this.loagingC(false);
            this.historialDocumentos();
            this.href_file=response.data.url;
            this.fname_download=response.data.file_name;
            this.loaded=true;
            $(".delFilePDF").click(); 
          }
        })
        .catch(error=>{
          console.log(error);
           this.carga_archivo=true;
           this.cleanViewF();
           this.loagingC(false);         
        });
      },
      historialDocumentos(){
        axios.post(`${process.env.APP_URL}/find-history-docs`,
          {
            id:this.id_user,
            id_portal:this.id_portal,
            plataforma:this.plataforma_user,
            tipo:this.tipo_file,
          })
          .then((response)=>{
            if(response.data.code=="200"){
              this.data_files=response.data.response;
            }else{
              this.data_files=[];
            }
          })
        .catch(error=>{
          console.log(error);            
        });
      },
      inputFile(data){
        this.filePdf = data;
        if(data.length==0){
          this.cancelaArchivo();
        }else{
          this.iframeSrc= URL.createObjectURL(data);
        }
      },
      cancelaArchivo(){
        this.iframeSrc=  this.iframeSrcOld;

      },
      accionStatus(id,status,email,plataforma,data){
        this.id_user=id;
        this.id_portal="0";
        this.status_descripcion='Activar';
        let pl=plataforma;
        if( data.user_portal!=null){  
          pl='TRAMITES.NL - ADMINISTRADOR DE LA TESORERIA';
          this.id_portal=data.user_portal.id;
        }
        this.plataforma_descripcion=pl;
        this.usuario_descripcion=email;
        this.status_user=status;
        this.plataforma_user=plataforma;
        if(status=='1'){ this.status_descripcion='Desactivar'; }
        else{
          this.data_registro=data;
        }
      },
      confirmarStatus(){
        if(this.status_descripcion=='Activar'){
          this.renderForm=false;    
          setTimeout(() => {
            $("#portlet-editar").modal("toggle");
          }, 500);
          this.renderForm=true;
          return;
        }
        axios.post(`${process.env.APP_URL}/status-usuarios`,{
          id:this.id_user,
          id_portal:this.id_portal,
          status:this.status_user,
          plataforma:this.plataforma_user
        })
        .then((response)=>{ 
          this.notifications(response.data.code,response.data.message);
          this.allusers();
        })
        .catch(error=>{
            console.log(error);
            this.carga_archivo=true;              
        });
      },
      dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'application/pdf'});
        return blob;
      }, 
      accionEditar(dato){
        this.renderForm=false;
        this.data_registro=dato;
        this.renderForm=true;
      },
      notifications(Code,Message){
        if(Code=="200"){  Command: toastr.success(Message, "Notifications");  }
        else{  Command: toastr.warning(Message, "Notifications"); }
      },
      load: function(){
        this.loaded = true;
      },
      loagingC(value, index, values) {
        return Vue.filter('loadingCharge')(value);
      },
      loadTable(){
        this.allusers();
      },
    },
    computed: {
    
    },
    mounted() {
      this.allusers();
    }
  }
</script>